import { Fragment, ReactNode, useRef } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useGetApplicationDetailsSectionQuery,
  useLazyApplicationDetailsFileDownloadQuery,
} from 'api/application';
import { ErrorAlert } from 'common/components/alerts';
import { FileDownload } from 'common/components/fileDownload';
import { GovUkTag } from 'common/components/govUkTag';
import { Loading } from 'common/components/loading';
import { EQuestionType } from 'enums/EQuestionType';
import { useCurrentUserPermissions, useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { ApplicationDetailsAnswerEditedIndicator } from 'pages/applicationPage/content/applicationDetails/ApplicationDetailsAnswerEditedIndicator';
import {
  EApplicationDetailsQuestionMenuType,
  ESectionType,
} from 'pages/applicationPage/content/applicationDetails/ApplicationDetailsTypes';
import { getTagVariant } from 'pages/applicationPage/content/applicationDetails/components';
import { useApplicationDetailsContext } from 'pages/applicationPage/content/applicationDetails/context';
import {
  ApplicationDetailsDialogs,
  IApplicationDetailsDialogs,
} from 'pages/applicationPage/content/applicationDetails/dialogs/ApplicationDetailsDialogs';
import {
  EApplicationDetailsItemStatus,
  EApplicationDetailsItemStatusLanguage,
  IApplicationDetailsItem,
  IApplicationSection,
} from 'types/applications/ApplicationDetailTypes';
import { extractErrorMessages } from 'util/ApiUtils';
import { getUkDateStringFromJsonDateString } from 'util/AppUtils';

export const ApplicationDetailsSectionOutlet = () => {
  const { selectedItemId, applicationCategoriesQuery } =
    useApplicationDetailsContext();

  const item = applicationCategoriesQuery.data?.categories
    .reduce<IApplicationDetailsItem[]>((prev, curr) => {
      return [...prev, ...curr.detailItems];
    }, [])
    .find(item => item.id === selectedItemId);

  if (!item) {
    return (
      <ErrorAlert msg={`Could not find item with id: ${selectedItemId}`} />
    );
  }

  return (
    <>
      <Header title={item.sectionName} status={item.taskStatus} />
      <Divider />
      <Box p={3}>
        <Answers item={item} />
      </Box>
    </>
  );
};

const Header = ({
  title,

  status,
}: {
  title: string;

  status: EApplicationDetailsItemStatus;
}) => {
  const { setSelectedItemId } = useApplicationDetailsContext();
  return (
    <Box bgcolor="grey.100" p={3}>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Typography variant="h2" fontSize="1.2em">
          {title}
        </Typography>
        <Box>
          <IconButton
            onClick={() => {
              setSelectedItemId(null);
            }}
            color="default"
            sx={{
              borderRadius: 0,
              background: 'grey.100',
              p: 1,
              m: 0,
            }}
            aria-label="Back"
            name="Back"
          >
            <FontAwesomeIcon
              icon={solid('chevron-circle-left')}
              size="xs"
              fixedWidth
            />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" gap={4}>
        <SubHeaderItem
          label="Status"
          value={
            <GovUkTag
              text={EApplicationDetailsItemStatusLanguage[status]}
              variant={getTagVariant(status)}
              size="sm"
            />
          }
        />
      </Box>
    </Box>
  );
};

const SubHeaderItem = ({
  label,
  value,
}: {
  label: string;
  value: ReactNode;
}) => {
  return (
    <Box display="flex" flexDirection="row" gap={2} alignItems="center">
      <Typography variant="body1" color="grey.800" fontSize="0.9em">
        {label}
      </Typography>

      {typeof value === 'string' ? (
        <Typography variant="body1" fontWeight={600} fontSize="0.9em">
          {value}
        </Typography>
      ) : (
        value
      )}
    </Box>
  );
};

const Answers = ({ item }: { item: IApplicationDetailsItem }) => {
  const { applicationId } = useApplicationDetailsContext();
  const sectionId = getSectionId(item.sectionName);
  const queryParam =
    !applicationId || !sectionId ? skipToken : { applicationId, sectionId };
  const { isLoading, error, data } =
    useGetApplicationDetailsSectionQuery(queryParam);

  if (isLoading) {
    return <Loading isOpen />;
  }

  if (error) {
    return <ErrorAlert msg={extractErrorMessages(error)} />;
  }

  if (!sectionId) {
    return <ErrorAlert msg="Could not calculate section id" />;
  }

  if (!data) {
    return <ErrorAlert msg="Could not get section data" />;
  }

  return <AnswersMain questions={data.questions} />;
};

const AnswersMain = ({
  questions,
}: {
  questions: IApplicationSection['questions'];
}) => {
  const theme = useTheme();

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasAdminApplicationEdit = doesUserHaveSinglePermission(
    'admin.application.edit'
  );

  const { closed } = useApplicationContext();

  const {
    handleCloseContextMenu,
    handleShowQuestionContextMenu,
    contextMenuAnchorEl,
    showContextMenuForQuestionTypeId,
  } = useApplicationDetailsContext();

  const applicationDetailsDialogsRef = useRef<IApplicationDetailsDialogs>();

  const allowQuestionEdit = (questionType: EQuestionType): boolean => {
    switch (questionType) {
      case EQuestionType.ResponsibleEntityContactDetails:
      case EQuestionType.RepresentativeContactDetails:
      case EQuestionType.FreeholderCompanyDetails:
      case EQuestionType.FreeholderContactDetails:
      case EQuestionType.FreeholderAddress:
      case EQuestionType.BuildingHeight:
      case EQuestionType.NumberOfStoreys:
      case EQuestionType.TotalCladdingArea:
      case EQuestionType.LifeSafetyRiskAssessment:
      case EQuestionType.AssessmentType:
      case EQuestionType.RecommendCladding:
      case EQuestionType.BuildingAddress:
      case EQuestionType.DeveloperAddress:
      case EQuestionType.FireRiskSummary:
      case EQuestionType.FireRiskReport:
        return true;
      default:
        return hasAdminApplicationEdit;
    }
  };

  return (
    <>
      {questions.map((q, i) => {
        const answerValue = extractAnswerValue(q.type, q.value);
        return (
          <Fragment key={q.title}>
            <Box display="flex" py={2.5} columnGap={2}>
              <Box flexBasis="40%">
                <Typography fontWeight={600} fontSize="0.9em">
                  {q.title}
                </Typography>
              </Box>
              {answerValue.type === 'file-array' ? (
                <Box flexGrow={1} sx={{ marginRight: '2rem' }}>
                  {answerValue.node}
                </Box>
              ) : (
                <Box flexBasis="35%">{answerValue.node}</Box>
              )}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                {q.questionType !== undefined ? (
                  <ApplicationDetailsAnswerEditedIndicator
                    questionType={q.questionType}
                  />
                ) : null}
                {q.questionType !== undefined ? (
                  <Box flexGrow={1}>
                    <IconButton
                      onClick={e => {
                        if (q.questionType !== undefined) {
                          handleShowQuestionContextMenu(
                            e.currentTarget,
                            q.questionType
                          );
                        }
                      }}
                      sx={{
                        borderRadius: 0,
                        background: theme.palette.grey[100],
                        p: 1,
                        m: 0,
                      }}
                      aria-label="Details menu"
                      name="Details menu"
                    >
                      <FontAwesomeIcon icon={faEllipsis} size="xs" fixedWidth />
                    </IconButton>
                  </Box>
                ) : null}
              </Box>

              {Boolean(contextMenuAnchorEl) &&
              showContextMenuForQuestionTypeId === q.questionType ? (
                <Menu
                  anchorEl={contextMenuAnchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  open={Boolean(contextMenuAnchorEl)}
                  onClose={() => handleCloseContextMenu()}
                >
                  {allowQuestionEdit(q.questionType) && !closed && (
                    <MenuItem
                      onClick={() => {
                        if (q.questionType !== undefined) {
                          handleCloseContextMenu();
                          applicationDetailsDialogsRef.current?.handleQuestionContextMenuButtonClick(
                            EApplicationDetailsQuestionMenuType.EditItem,
                            q.questionType,
                            q.value.toString()
                          );
                        }
                      }}
                      aria-label="Edit"
                    >
                      Edit
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      if (q.questionType !== undefined) {
                        handleCloseContextMenu();
                        applicationDetailsDialogsRef.current?.handleQuestionContextMenuButtonClick(
                          EApplicationDetailsQuestionMenuType.ViewHistory,
                          q.questionType,
                          q.value.toString()
                        );
                      }
                    }}
                    aria-label="Edit"
                  >
                    History
                  </MenuItem>
                </Menu>
              ) : null}
            </Box>
            {i + 1 !== questions.length ? <Divider /> : null}
          </Fragment>
        );
      })}

      <ApplicationDetailsDialogs ref={applicationDetailsDialogsRef} />
    </>
  );
};

const AnswerString = ({ value }: { value: string }) => {
  return <Typography fontSize="0.9em">{value}</Typography>;
};

const FileDownloadWrapper = ({
  fileName,
  fileUri,
}: {
  fileName: string;
  fileUri: string;
}) => {
  const { applicationId } = useApplicationDetailsContext();
  const { createErrorSnackbar } = useLocalSnackbar();
  const [trigger, result] = useLazyApplicationDetailsFileDownloadQuery();

  const handleClick = () => {
    const [fileId] = fileUri.split('/').slice(-1);

    if (!applicationId || !fileId) {
      createErrorSnackbar(
        'Could not get file. applicationId or fileId is empty'
      );
      return;
    }

    trigger({ applicationId, fileId, fileName });
  };

  return (
    <FileDownload
      isError={result.isError}
      error={result.error}
      isLoading={result.isLoading}
      displayValue={<AnswerString value={fileName} />}
      handleClick={handleClick}
    />
  );
};

interface IExtractAnswerValueResult {
  type: IApplicationSection['questions'][number]['type'] | 'error';
  node: ReactNode;
}

const getAnswerResult = (
  type: IExtractAnswerValueResult['type'],
  node: ReactNode
) => ({
  type,
  node,
});

const extractAnswerValue = (
  type: IApplicationSection['questions'][number]['type'],
  value: IApplicationSection['questions'][number]['value']
): IExtractAnswerValueResult => {
  if (type === 'string' && typeof value === 'string') {
    return getAnswerResult(type, <AnswerString value={value} />);
  }

  if (type === 'number' && typeof value === 'number') {
    return getAnswerResult(type, <AnswerString value={value.toString()} />);
  }

  if (type === 'boolean' && typeof value === 'boolean') {
    return getAnswerResult(
      type,
      <AnswerString value={value === true ? 'Yes' : 'No'} />
    );
  }

  if (type === 'string-array' && Array.isArray(value)) {
    return getAnswerResult(
      type,
      value.map((v, i) =>
        typeof v === 'string' ? (
          <Box key={i}>
            <AnswerString value={v} />
          </Box>
        ) : null
      )
    );
  }

  if (type === 'file-array' && Array.isArray(value)) {
    return getAnswerResult(
      type,
      value.map((file, i) => {
        return typeof file === 'object' &&
          'fileName' in file &&
          'fileUri' in file ? (
          <Box key={i}>
            <FileDownloadWrapper
              fileName={file.fileName}
              fileUri={file.fileUri}
            />
          </Box>
        ) : null;
      })
    );
  }

  if (type === 'date' && typeof value === 'string') {
    return getAnswerResult(
      type,
      <AnswerString value={getUkDateStringFromJsonDateString(value)} />
    );
  }

  return getAnswerResult('error', null);
};

const _sectionTitleMap = {
  funding: ESectionType.AlternateFunding,
  bank: ESectionType.BankAccount,
  building: ESectionType.Building,
  declaration: ESectionType.Declaration,
  fire: ESectionType.FireRisk,
  lease: ESectionType.LeaseholderAgreement,
  responsible: ESectionType.ResponsibleEntity,
} as const;

const getSectionId = (sectionName: string) => {
  if (!sectionName) {
    return null;
  }

  for (const [key, value] of Object.entries(_sectionTitleMap)) {
    if (sectionName.toLocaleLowerCase().includes(key)) {
      // Found a matching section, return the value
      return value;
    }
  }

  // No section found, return null
  return null;
};
