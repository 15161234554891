import { applicationApi } from 'api/application/applicationApi';
import { EQuestionType } from 'enums/EQuestionType';
import {
  GetApplicationQuestionHistoryResponse,
  IAddress,
  IAssessmentType,
  IBankAccountVerificationContactDetails,
  IBsrRegistrationCode,
  IBuildingHeight,
  IBuildingName,
  ICompanyDetails,
  IContactDetails,
  IEditApplicationFile,
  IFraewCost,
  IFraewInstructionDate,
  IFraewPeerReviewer,
  IFraewReportAuthor,
  IFraewSurveyCompany,
  IFraewSurveyDate,
  IGetAddressRequest,
  IGetApplicationQuestionHistoryRequest,
  IGetCompanyDetailsRequest,
  IGetContactDetailsRequest,
  ILifeSafetyRiskAssessment,
  INumberOfStoreys,
  IProjectMilestones,
  IPtfsAmount,
  IPtfsRequired,
  IRecommendCladding,
  ITotalCladdingArea,
  IUkBankAccountDetails,
  IVatNumber,
  IWhoseBankAccount,
} from 'types/applications/ApplicationEditTypes';
import { QueryTags } from 'util/ApiUtils';

export const applicationEditEndpoints = applicationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getApplicationQuestionHistory: builder.query<
      GetApplicationQuestionHistoryResponse,
      IGetApplicationQuestionHistoryRequest
    >({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: params =>
        `${params.applicationId}/edit/history/${params.questionType}`,
    }),
    getUkBankAccountDetails: builder.query<IUkBankAccountDetails, string>({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: applicationId => `${applicationId}/edit/bankAccount`,
    }),
    editUkBankAccountDetails: builder.mutation<any, IUkBankAccountDetails>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationDetailsEdit,
        QueryTags.ApplicationGrantFunding,
        QueryTags.ApplicationVendorDetails,
        QueryTags.ApplicationPaymentRecommendationDetails,
        QueryTags.ApplicationPaymentReleaseDetails,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/bankAccount`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getBankAccountVerificationContactDetails: builder.query<
      IBankAccountVerificationContactDetails,
      string
    >({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: applicationId =>
        `${applicationId}/edit/bankAccountVerificationContact`,
    }),
    editBankAccountVerificationContactDetails: builder.mutation<
      void,
      IBankAccountVerificationContactDetails
    >({
      invalidatesTags: [
        QueryTags.ApplicationDetails,

        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/bankAccountVerificationContact`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editPtfsRequired: builder.mutation<any, IPtfsRequired>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
        QueryTags.ApplicationEligibility,
        QueryTags.ApplicationGrantFunding,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/ptfsRequired`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editPtfsAmount: builder.mutation<any, IPtfsAmount>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
        QueryTags.ApplicationEligibility,
        QueryTags.ApplicationGrantFunding,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/ptfsamount`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editVatNumber: builder.mutation<any, IVatNumber>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
        QueryTags.ApplicationVendorDetails,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/vatnumber`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editWhoseBankAccount: builder.mutation<any, IWhoseBankAccount>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
        QueryTags.ApplicationGrantFunding,
        QueryTags.ApplicationVendorDetails,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/whosebankaccount`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getBuildingAddress: builder.query<IAddress, IGetAddressRequest>({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: payload => `${payload.applicationId}/edit/buildingAddress`,
    }),
    editBuildingAddress: builder.mutation<any, IAddress>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/buildingAddress`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getAddress: builder.query<IAddress, IGetAddressRequest>({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: payload => {
        switch (payload.questionType) {
          case EQuestionType.BuildingAddress:
          case EQuestionType.DeveloperAddress:
            return `${payload.applicationId}/edit/buildingDetailsAddress/${payload.questionType}`;
          case EQuestionType.ResponsibleEntityAddress:
          case EQuestionType.RepresentativeAddress:
          default:
            return `${payload.applicationId}/edit/address/${payload.questionType}`;
        }
      },
    }),
    editAddress: builder.mutation<any, IAddress>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => {
        switch (payload.questionType) {
          case EQuestionType.BuildingAddress:
          case EQuestionType.DeveloperAddress:
            return {
              url: `/${payload.applicationId}/edit/buildingDetailsAddress/${payload.questionType}`,
              method: 'PUT',
              body: payload,
            };

          case EQuestionType.ResponsibleEntityAddress:
          case EQuestionType.RepresentativeAddress:
          default:
            return {
              url: `/${payload.applicationId}/edit/address/${payload.questionType}`,
              method: 'PUT',
              body: payload,
            };
        }
      },
    }),
    getCompanyDetails: builder.query<
      ICompanyDetails,
      IGetCompanyDetailsRequest
    >({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: payload =>
        `${payload.applicationId}/edit/companyDetails/${payload.questionType}`,
    }),
    editCompanyDetails: builder.mutation<any, ICompanyDetails>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/companyDetails/${payload.questionType}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getContactDetails: builder.query<
      IContactDetails,
      IGetContactDetailsRequest
    >({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: payload =>
        `${payload.applicationId}/edit/contactDetails/${payload.questionType}`,
    }),
    editContactDetails: builder.mutation<any, IContactDetails>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/contactDetails/${payload.questionType}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editBuildingHeight: builder.mutation<any, IBuildingHeight>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
        QueryTags.ApplicationEligibility,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/buildingHeight`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editNumberOfStoreys: builder.mutation<any, INumberOfStoreys>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/numberOfStoreys`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editTotalCladdingArea: builder.mutation<any, ITotalCladdingArea>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
        QueryTags.ApplicationGrantFunding,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/totalCladdingArea`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editLifeSafetyRiskAssessment: builder.mutation<
      any,
      ILifeSafetyRiskAssessment
    >({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/lifeSafetyRiskAssessment`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editAssessmentType: builder.mutation<any, IAssessmentType>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/assessmentType`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editRecommendCladding: builder.mutation<any, IRecommendCladding>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/recommendCladding`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editApplicationFile: builder.mutation<any, IEditApplicationFile>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => {
        switch (payload.questionType) {
          case EQuestionType.FireRiskReport:
            return {
              url: `/${payload.applicationId}/edit/fireRiskReportFile`,
              method: 'PUT',
              body: payload,
            };
          case EQuestionType.FireRiskSummary:
          default:
            return {
              url: `/${payload.applicationId}/edit/fireRiskSummaryFile`,
              method: 'PUT',
              body: payload,
            };
        }
      },
    }),
    editBsrRegistrationCode: builder.mutation<any, IBsrRegistrationCode>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/bsrRegistrationCode`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editBuildingName: builder.mutation<any, IBuildingName>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
        QueryTags.ApplicationStatus,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/buildingName`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editFraewSurveyCompany: builder.mutation<any, IFraewSurveyCompany>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/fraewSurveyCompany`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editFraewSurveyDate: builder.mutation<any, IFraewSurveyDate>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/fraewSurveyDate`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editFraewInstructionDate: builder.mutation<any, IFraewInstructionDate>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/fraewInstructionDate`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editFraewReportAuthor: builder.mutation<any, IFraewReportAuthor>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/fraewReportAuthor`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editFraewPeerReviewer: builder.mutation<any, IFraewPeerReviewer>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/fraewPeerReviewer`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editFraewCost: builder.mutation<any, IFraewCost>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/fraewCost`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getProjectMilestones: builder.query<
      IProjectMilestones,
      string
    >({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: applicationId => `${applicationId}/edit/projectMilestones`,
    }),
    editProjectMilestones: builder.mutation<any, IProjectMilestones>({
      invalidatesTags: [
        QueryTags.ApplicationDetailsEdit,
        QueryTags.ApplicationLiveProjectOverview
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/projectMilestones`,
        method: 'PUT',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetApplicationQuestionHistoryQuery,
  useLazyGetUkBankAccountDetailsQuery,
  useEditUkBankAccountDetailsMutation,
  useLazyGetBankAccountVerificationContactDetailsQuery,
  useEditBankAccountVerificationContactDetailsMutation,
  useEditPtfsRequiredMutation,
  useEditPtfsAmountMutation,
  useEditVatNumberMutation,
  useEditWhoseBankAccountMutation,
  useGetAddressQuery,
  useEditAddressMutation,
  useGetBuildingAddressQuery,
  useEditBuildingAddressMutation,
  useGetCompanyDetailsQuery,
  useEditCompanyDetailsMutation,
  useGetContactDetailsQuery,
  useEditContactDetailsMutation,
  useEditBuildingHeightMutation,
  useEditNumberOfStoreysMutation,
  useEditTotalCladdingAreaMutation,
  useEditLifeSafetyRiskAssessmentMutation,
  useEditAssessmentTypeMutation,
  useEditRecommendCladdingMutation,
  useEditApplicationFileMutation,
  useEditBsrRegistrationCodeMutation,
  useEditBuildingNameMutation,
  useEditFraewSurveyCompanyMutation,
  useEditFraewSurveyDateMutation,
  useEditFraewInstructionDateMutation,
  useEditFraewReportAuthorMutation,
  useEditFraewPeerReviewerMutation,
  useEditFraewCostMutation,
  useGetProjectMilestonesQuery,
  useEditProjectMilestonesMutation,
} = applicationEditEndpoints;
