import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import {
  useAddPaymentRequestReferralMutation,
  useEditClosingPaymentRequestChecksMutation,
  useGetClosingPaymentRequestChecksQuery,
  useGetPaymentRequestOverviewQuery,
} from 'api/application/paymentRequestApi';
import { OnHoldBanner } from 'common/components/OnOffHold/OnHoldBanner';
import { OnOffHold } from 'common/components/OnOffHold/OnOffHold';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import { AlertForChangedData, WarningAlert } from 'common/components/alerts';
import { LeavePageGuard } from 'common/components/leavePageGuard';
import { Loading } from 'common/components/loading';
import { useReferToHomesEnglandModal } from 'common/components/referToHomesEnglandModal';
import { useReferToThirdPartyDrawer } from 'common/components/referrals/thirdPartyReferrals';
import {
  getNumberOfActiveReferrals,
  getReferralsArray,
  useReferralsList,
} from 'common/components/referrals/utils';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import {
  useCurrentUser,
  useCurrentUserPermissions,
  useInvalidateTasks,
  useLocalSnackbar,
  useModalState,
} from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context';
import { useClosingPaymentRequestContext } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/Closing/context';
import { ClosingPaymentRequestChecksSectionInfoPanel } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/Closing/sections/ClosingPaymentRequestChecksSection/ClosingPaymentRequestChecksSectionInfoPanel';
import { mapPaymentRequestHeReferrals } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/Monthly/utils';
import {
  ClosingPaymentRequestChecksBooleans,
  ClosingPaymentRequestChecksForm,
  EPaymentRecommendationStatus,
  IClosingPaymentRequestChecks,
  closingPaymentRequestBooleanProps,
} from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import { PaymentRequestKpiSection } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common';
import { ApprovePaymentRequestModal } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common/ApprovePaymentRequestModal';
import { RecommendPaymentRequestModal } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common/RecommendPaymentRequestModal';
import { RejectPaymentRequestModal } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common/RejectPaymentRequestModal';
import { ClosingPaymentRequestNotificationBanner } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common/banners/ClosingPaymentRequestNotificationBanner';
import { VendorPaymentApprovalWaitingBanner } from 'pages/applicationPage/content/payments/common/VendorPaymentApprovalWaitingBanner';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';
import { EReferralRecordType } from 'types/applications/ReferralTypes';
import { extractErrorMessages } from 'util/ApiUtils';
import { setServerSideFormErrors } from 'util/formUtils';

export const ClosingPaymentRequestChecksSection = () => {
  const { isVendorPaymentApprovalWaiting } = useApplicationContext();
  const { applicationId, selectedItem } = useApplicationLiveProjectContext();

  var request = selectedItem?.id
    ? {
        applicationId: applicationId,
        paymentRequestId: selectedItem.id,
      }
    : skipToken;

  const query = useGetClosingPaymentRequestChecksQuery(request);
  const { data: overViewData } = useGetPaymentRequestOverviewQuery(request);

  if (query.isLoading) {
    return <Loading isOpen />;
  }

  if (query.isSuccess) {
    const data = query.data;
    const initialData = {
      ...data,
    };

    return (
      <RoundBorderBox>
        <Box sx={{ padding: 2, borderBottom: '1px solid lightgray' }}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h3" fontWeight={800} fontSize="1.3em">
              Confirm payment request
            </Typography>
          </Box>
        </Box>

        <Stack p={2} rowGap={2}>
          {selectedItem && (
            <PaymentRequestKpiSection paymentRequestId={selectedItem.id} />
          )}
          {overViewData?.paymentRecommendation &&
            [
              EPaymentRecommendationStatus.Sent,
              EPaymentRecommendationStatus.Approved,
              EPaymentRecommendationStatus.Rejected,
              EPaymentRecommendationStatus.Expired,
            ].includes(overViewData.paymentRecommendation) && (
              <ClosingPaymentRequestNotificationBanner
                status={overViewData.paymentRecommendation}
              />
            )}
          {isVendorPaymentApprovalWaiting &&
            overViewData?.paymentRecommendation &&
            [
              EPaymentRecommendationStatus.Submitted,
              EPaymentRecommendationStatus.Sent,
            ].includes(overViewData.paymentRecommendation) && (
              <VendorPaymentApprovalWaitingBanner />
            )}
          <ClosingPaymentRequestChecksFormMain initialData={initialData} />
        </Stack>
      </RoundBorderBox>
    );
  }

  return null;
};

const ClosingPaymentRequestChecksFormMain = ({
  initialData,
}: {
  initialData: IClosingPaymentRequestChecks;
}) => {
  const { isHeUser, paymentApprovalLevel } = useCurrentUser();
  const { isVendorPaymentApprovalWaiting, closed } = useApplicationContext();
  const { applicationId, readOnly, currentStatus } =
    useClosingPaymentRequestContext();
  const { selectedItem } = useApplicationLiveProjectContext();

  const isStatusRecommend =
    currentStatus === EPaymentRecommendationStatus.Submitted ||
    currentStatus === EPaymentRecommendationStatus.Todo;

  const isStatusSent = currentStatus === EPaymentRecommendationStatus.Sent;
  const isApprovedOrRejected =
    currentStatus === EPaymentRecommendationStatus.Approved ||
    currentStatus === EPaymentRecommendationStatus.Rejected ||
    currentStatus === EPaymentRecommendationStatus.Paid;

  const hasPaymentApprovalLevel =
    paymentApprovalLevel === undefined ||
    (paymentApprovalLevel !== undefined &&
      initialData.paymentAmount <= paymentApprovalLevel);

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const doesUserHaveRecommendPaymentPermission = doesUserHaveSinglePermission(
    'payment-recommend.edit'
  );
  const doesUserHaveApproveRejectPaymentPermission =
    doesUserHaveSinglePermission('payment-release.edit');

  const [editClosingPaymentRequestChecks] =
    useEditClosingPaymentRequestChecksMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IClosingPaymentRequestChecks>({
    defaultValues: initialData,
  });

  const {
    reset,
    getValues,
    watch,
    trigger,
    formState: { isDirty, isSubmitting },
  } = form;

  const {
    isShowing: isRecommendPaymentRequestModalShowing,
    showModal: showRecommendPaymentRequestModal,
    hideModal: hideRecommendPaymentRequestModal,
  } = useModalState();

  const {
    isShowing: isApprovePaymentRequestModalShowing,
    showModal: showApprovePaymentRequestModalShowing,
    hideModal: hideApprovePaymentRequestModalShowing,
  } = useModalState();

  const {
    isShowing: isRejectPaymentRequestModalShowing,
    showModal: showRejectPaymentRequestModalShowing,
    hideModal: hideRejectPaymentRequestModalShowing,
  } = useModalState();

  const [refer, referStatus] = useAddPaymentRequestReferralMutation();

  const {
    showReferToHomesEnglandModal,
    hideReferToHomesEnglandModal,
    renderReferToHomesEnglandModal,
  } = useReferToHomesEnglandModal();

  const { referrals: heReferrals, paymentRequestId } =
    useClosingPaymentRequestContext();

  const mappedHeReferrals = mapPaymentRequestHeReferrals(heReferrals);

  const { query: referralsQuery } = useReferralsList({
    referralRecordType: EReferralRecordType.PaymentRequest,
    paymentRequestId: paymentRequestId,
    heReferrals: mappedHeReferrals,
  });

  const thirdPartyReferrals = referralsQuery.isSuccess
    ? getReferralsArray(referralsQuery.data)
    : [];

  const referrals = [...mappedHeReferrals, ...thirdPartyReferrals];

  const isActiveReferrals = getNumberOfActiveReferrals(referrals) > 0;

  const { invalidateTasks } = useInvalidateTasks();

  const handleReferToHeConfirmClick = async (referralComments: string) => {
    if (!selectedItem?.id) {
      createErrorSnackbar(
        "Couldn't progress refer to HE. No selected payment request id found"
      );
      return;
    }

    await refer({
      applicationId,
      paymentRequestId: selectedItem.id,
      reason: referralComments,
    })
      .unwrap()
      .then(() => {
        createSuccessSnackbar('Closing Payment Request successfully referred.');
        invalidateTasks();
        hideReferToHomesEnglandModal();
      })
      .catch(error => {
        createErrorSnackbar(extractErrorMessages(error));
      });
  };

  const onSubmit = async (approve?: boolean) => {
    if (!selectedItem?.id) {
      createErrorSnackbar(
        "Couldn't submit. No selected payment request id found"
      );
      return;
    }

    try {
      const formData = getValues();
      formData.applicationId = applicationId;
      formData.paymentRequestId = selectedItem.id;
      formData.approve = approve;
      await editClosingPaymentRequestChecks(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(
            `Closing Payment Request Checks updated successfully`
          );
          reset({}, { keepValues: true });
          if (approve) {
            showRecommendPaymentRequestModal();
          }
        })
        .catch(error => {
          if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit Closing Payment Request checks`);
    }
  };

  const onApprove = async () => {
    const isValid = await trigger();
    if (!isValid) {
      createErrorSnackbar('Please complete all fields and try again');
      return;
    }
    if (!selectedItem?.id) {
      createErrorSnackbar(
        "Couldn't submit. No selected payment request id found"
      );
      return;
    }
    showApprovePaymentRequestModalShowing();
  };

  const isApproveButtonDisabled = watch(closingPaymentRequestBooleanProps).some(
    entry => entry !== true
  );

  const ChecksQuestion: JSX.Element = (
    <>
      <Typography lineHeight="1.167" fontWeight={600} fontSize="1em">
        Have the following checks been completed:
      </Typography>
      <ul>
        <li>Correct building displayed on the cost report</li>
        <li>Signed off by the GCO for this application</li>
        <li>Is the cost report displayed in the format specified?'</li>
      </ul>
    </>
  );

  const thirdPartyReferralDrawer = useReferToThirdPartyDrawer();
  const handleReferToThirdPartyClick = () => {
    if (!selectedItem) {
      createErrorSnackbar('Could not get payment request');
      return;
    }

    thirdPartyReferralDrawer.showReferDrawer({
      referralType: 'newReferral',
      referralRecordType: EReferralRecordType.PaymentRequest,
      paymentRequestId: selectedItem.id,
    });
  };

  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.ClosingReport,
  });

  return (
    <>
      <FormProvider {...form}>
        <LeavePageGuard
          blockNavigationIf={isDirty && !isSubmitting}
          message="Are you sure you want to leave? Any unsaved changes to the Closing Payment Request Checks will be lost."
        />

        {isOnHoldForThisType && (
          <OnHoldBanner holdType={EOnHoldType.ClosingReport} />
        )}

        <form>
          <AlertForChangedData isDirty={isDirty} />

          <WarningAlert
            alertText="You cannot approve the closing payment request until the cost profile has been confirmed."
            isShow={initialData.isPaymentRequestCostsConfirmed === false}
          />

          <Stack rowGap={2}>
            <Grid container spacing={2}>
              <Grid xs={9} item>
                <Stack rowGap={2}>
                  <QuestionAndAnswer
                    questionLabel={ChecksQuestion}
                    fieldName="basicChecksCompleted"
                    readOnly={readOnly || isOnHoldForThisType}
                  />
                  <QuestionAndAnswer
                    questionLabel="Do you have sufficient evidence to cover the total grant claimed for the project?"
                    fieldName="sufficientEvidenceToCoverTotalGrantClaimed"
                    readOnly={readOnly || isOnHoldForThisType}
                  />
                  <QuestionAndAnswer
                    questionLabel="Have the works identified in the works package been completed?"
                    fieldName="worksIdentifiedCompleted"
                    readOnly={readOnly || isOnHoldForThisType}
                  />
                  <QuestionAndAnswer
                    questionLabel="Has the risk level been mitigated to a tolerable level?"
                    fieldName="riskLevelMitigatedToTolerableLevel"
                    readOnly={readOnly || isOnHoldForThisType}
                  />
                  <QuestionAndAnswer
                    questionLabel="Has a practical completion certification been provided and approved by the grant certifying officer?"
                    fieldName="practicalCompletionCertificationProvidedAndApproved"
                    readOnly={readOnly || isOnHoldForThisType}
                  />

                  <QuestionAndAnswer
                    questionLabel="Has the applicant uploaded certification from building control (11-18 metres) or the building safety regulator (above 18 metres)?"
                    fieldName="uploadedCertification"
                    readOnly={readOnly || isOnHoldForThisType}
                  />
                  <QuestionAndAnswer
                    questionLabel="Has the applicant provided evidence of communication with leaseholders and provided a link to the feedback survey?"
                    fieldName="evidenceProvidedToLeaseHoldersAndSurvey"
                    readOnly={readOnly || isOnHoldForThisType}
                  />
                  <QuestionAndAnswer
                    questionLabel="Are you recommending this payment for approval from Homes England?"
                    fieldName="recommendPaymentApproval"
                    readOnly={readOnly || isOnHoldForThisType}
                  />
                </Stack>
              </Grid>
              <Grid xs={3} item>
                <ClosingPaymentRequestChecksSectionInfoPanel />
              </Grid>
            </Grid>

            {!isApprovedOrRejected && !closed && (
              <Grid container spacing={1}>
                <Grid xs={12} item>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    p={3}
                    bgcolor="grey.100"
                  >
                    {isHeUser ? (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={handleReferToThirdPartyClick}
                        disabled={isOnHoldForThisType}
                      >
                        Refer
                      </Button>
                    ) : null}
                    {!isHeUser ? (
                      <Button
                        variant="outlined"
                        disabled={isOnHoldForThisType}
                        onClick={showReferToHomesEnglandModal}
                      >
                        Refer to HE
                      </Button>
                    ) : null}
                    <OnOffHold holdType={EOnHoldType.ClosingReport} />
                    <Box gap={2} display="flex">
                      {(isStatusRecommend || isStatusSent) && (
                        <Button
                          onClick={() => showRejectPaymentRequestModalShowing()}
                          variant="outlined"
                          disabled={
                            isOnHoldForThisType ||
                            !doesUserHaveApproveRejectPaymentPermission
                          }
                        >
                          Reject
                        </Button>
                      )}
                      {!readOnly && (
                        <Button
                          variant="outlined"
                          disabled={isOnHoldForThisType}
                          onClick={() => onSubmit()}
                        >
                          Save
                        </Button>
                      )}
                      {isStatusRecommend && (
                        <Button
                          variant="contained"
                          disabled={
                            isVendorPaymentApprovalWaiting ||
                            isApproveButtonDisabled ||
                            isOnHoldForThisType ||
                            !initialData.isPaymentRequestCostsConfirmed ||
                            !doesUserHaveRecommendPaymentPermission
                          }
                          onClick={() => onSubmit(true)}
                        >
                          Recommend Payment
                        </Button>
                      )}
                      {isStatusSent && (
                        <Button
                          variant="contained"
                          disabled={
                            isVendorPaymentApprovalWaiting ||
                            isApproveButtonDisabled ||
                            isOnHoldForThisType ||
                            !doesUserHaveApproveRejectPaymentPermission ||
                            isActiveReferrals ||
                            !hasPaymentApprovalLevel
                          }
                          onClick={() => onApprove()}
                        >
                          Approve Payment
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12} item>
                  <Box
                    display="flex"
                    justifyContent="center"
                    p={3}
                    bgcolor="grey.100"
                  >
                    {!hasPaymentApprovalLevel && (
                      <Typography
                        fontWeight={600}
                        fontSize="1em"
                        color={({ palette }) => palette.warning.main}
                      >
                        You do not have permission to approve this payment
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
            )}
          </Stack>
        </form>
      </FormProvider>

      {thirdPartyReferralDrawer.renderReferDrawer()}

      {renderReferToHomesEnglandModal({
        handleConfirmClick: handleReferToHeConfirmClick,
        referStatus: {
          isLoading: referStatus.isLoading,
          isError: referStatus.isError,
          error: referStatus.error,
        },
      })}

      {selectedItem?.id && isRecommendPaymentRequestModalShowing && (
        <RecommendPaymentRequestModal
          applicationId={applicationId}
          paymentRequestId={selectedItem?.id}
          onConfirm={() => hideRecommendPaymentRequestModal()}
          onCancel={() => hideRecommendPaymentRequestModal()}
        />
      )}

      {selectedItem?.id && isRejectPaymentRequestModalShowing && (
        <RejectPaymentRequestModal
          applicationId={applicationId}
          paymentRequestId={selectedItem?.id}
          onConfirm={() => hideRejectPaymentRequestModalShowing()}
          onCancel={() => hideRejectPaymentRequestModalShowing()}
        />
      )}

      {selectedItem?.id && isApprovePaymentRequestModalShowing && (
        <ApprovePaymentRequestModal
          applicationId={applicationId}
          paymentRequestId={selectedItem?.id}
          onConfirm={() => hideApprovePaymentRequestModalShowing()}
          onCancel={() => hideApprovePaymentRequestModalShowing()}
        />
      )}
    </>
  );
};

const reasonValidationRules = {
  maxLength: { value: 999, message: 'Max length is 1,000' },
  required: { value: true, message: 'Please provide a reason.' },
};

const QuestionAndAnswer = ({
  readOnly,
  fieldName,
  questionLabel,
}: {
  readOnly: boolean;
  fieldName: keyof ClosingPaymentRequestChecksBooleans;
  questionLabel: string | JSX.Element;
}) => {
  const { control, watch } = useFormContext<ClosingPaymentRequestChecksForm>();
  const [field] = watch([fieldName]);
  return (
    <Box
      sx={{
        backgroundColor: 'grey.100',
        borderRadius: '5px',
        p: 3,
      }}
    >
      <Stack rowGap={3}>
        <Box>
          <Typography variant="h3" fontWeight={600} fontSize="1em">
            {questionLabel}
          </Typography>
          <Controller
            name={fieldName}
            rules={{
              validate: {
                required: value => {
                  if (value === null || value === undefined) {
                    return 'Required';
                  }

                  return true;
                },
              },
            }}
            render={({ fieldState, field: { onChange, ...fieldProps } }) => (
              <FormControl>
                <ToggleButtonGroup
                  {...fieldProps}
                  onChange={(_, val) => {
                    if (val !== null) {
                      onChange(val);
                    }
                  }}
                  exclusive
                  disabled={readOnly}
                  sx={{
                    width: 'fit-content',
                  }}
                >
                  <ToggleButton value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
                {fieldState.error?.message ? (
                  <FormHelperText error>
                    {fieldState.error?.message}
                  </FormHelperText>
                ) : null}
              </FormControl>
            )}
          />
        </Box>

        {field !== null && (
          <Box>
            <Typography variant="h3" fontWeight={600} fontSize="1em">
              Provide Reason
            </Typography>
            <Controller
              control={control}
              name={`${fieldName}Reason`}
              rules={reasonValidationRules}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  value={field.value ?? ''}
                  fullWidth
                  error={
                    (!!fieldState.error && field.value === undefined) ||
                    field.value?.length >= 1000
                  }
                  helperText={fieldState.error ? fieldState.error?.message : ''}
                  multiline
                  disabled={readOnly}
                  rows={3}
                  inputProps={{
                    maxLength: 1000,
                  }}
                  sx={{
                    mb: 0,
                    boxShadow: 'none',
                    // Selector for error message
                    'p:last-child': {
                      backgroundColor: 'grey.100',
                      color: 'red',
                      margin: 0,
                      padding: '1rem',
                    },
                  }}
                />
              )}
            />
          </Box>
        )}
      </Stack>
    </Box>
  );
};
