import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { add, parseISO } from 'date-fns';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useEditFraewSurveyDateMutation } from 'api/application/applicationEditApi';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { zFraewSurveyDateSchema } from 'types/applications/ApplicationEdit.zod';
import { IFraewSurveyDate } from 'types/applications/ApplicationEditTypes';
import { getDateOnlyIsoString } from 'util/DateUtils';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IFraewSurveyDate) =>
  nameof<IFraewSurveyDate>(fieldName);

interface IEditFraewSurveyDateDrawerProps {
  onClose: () => void;
  onSuccess: () => void;
  currentValue: string;
}

export function EditFraewSurveyDateDrawer({
  onClose,
  onSuccess,
  currentValue,
}: IEditFraewSurveyDateDrawerProps) {
  return (
    <StyledDrawer
      anchor="right"
      open
      onClose={() => {
        onClose();
      }}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h1">Edit FRAEW Survey Date</Typography>
          <Typography variant="h3">{new Date(currentValue).toLocaleDateString("en-GB")}</Typography>
        </DialogTitle>
        <EditFraewSurveyDateForm onSuccess={onSuccess} onClose={onClose} />
      </Box>
    </StyledDrawer>
  );
}

interface IEditFraewSurveyDateFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditFraewSurveyDateForm = ({
  onClose,
  onSuccess,
}: IEditFraewSurveyDateFormProps) => {
  const { applicationId } = useApplicationContext();

  const [editFraewSurveyDate, editFraewSurveyDateResult] =
    useEditFraewSurveyDateMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IFraewSurveyDate>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zFraewSurveyDateSchema),
  });
  const { handleSubmit, reset } = form;

  const onSubmit = async (formData: IFraewSurveyDate) => {
    try {
      formData.applicationId = applicationId;
      await editFraewSurveyDate(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`FRAEW survey date updated successfully`);
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit FRAEW survey date`);
    }
  };

  return (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        style={{ width: '100%' }}
      >
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <Controller
                name={getName('surveyDate')}
                render={({
                  field: { onChange, value, ...field },
                  formState: { errors },
                }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      {...field}
                      format="dd/MM/yyyy"
                      maxDate={add(new Date(), { days: -1 })}
                      label="FRAEW Survey Date"
                      value={parseISO(value)}
                      onChange={val => (val ? onChange(getDateOnlyIsoString(val)) : null)}
                      slotProps={{
                        textField: {
                          sx: { mr: '1rem' },
                          error: !!errors.dateOfBirth,
                          helperText: errors.dateOfBirth?.message,
                          fullWidth: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={getName('changeReason')}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    required
                    label="Comments"
                    helperText={fieldState.error?.message}
                    multiline
                    autoComplete="off"
                    inputProps={{ maxLength: 1000 }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={editFraewSurveyDateResult.isLoading}
            >
              Update Answers
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </form>
    </FormProvider>
  );
};
