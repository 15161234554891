import {
  GetApplicationQuestionHistoryResponse,
  IAddress,
  IBankAccountVerificationContactDetails,
  ICompanyDetails,
  IContactDetails,
  IProjectMilestones,
  IUkBankAccountDetails,
} from 'types/applications/ApplicationEditTypes';

export const applicationQuestionHistory: GetApplicationQuestionHistoryResponse =
  [
    {
      fieldName: 'Account Number',
      isFile: false,
      oldValue: '12345678',
      newValue: '87654321',
      dateChanged: '2024-02-01 10:00:00',
      changedByUser: 'Joe Bloggs',
      changeReason: 'Incorrect bank account details entered by the applicant',
    },
    {
      fieldName: 'Sort Code',
      isFile: false,
      oldValue: '123456',
      newValue: '654321',
      dateChanged: '2024-02-01 10:00:00',
      changedByUser: 'Joe Bloggs',
      changeReason: 'Incorrect bank account details entered by the applicant',
    },
  ];

export const applicationEditBankAccount: IUkBankAccountDetails = {
  accountName: 'Account Name',
  bankName: 'Bank Name',
  branchName: 'Branch Name',
  accountNumber: 'Account Number',
  sortCode: 'Sort Code',
};

export const applicationEditBankAccountVerificationContact: IBankAccountVerificationContactDetails = {
  contactName: 'Joe Bloggs',
  contactNumber: '01234123456',
};

export const applicationAddress: IAddress = {
  nameNumber: '23',
  addressLine1: 'Mock Street Name',
  addressLine2: '',
  city: 'Oxford',
  county: 'Oxfordshire',
  postcode: 'OX33 1ER',
  changeReason: 'Incorrect address entered by the applicant',
};

export const applicationCompanyDetails: ICompanyDetails = {
  companyName: 'Davies Group Ltd.',
  companyRegistrationNumber: '1234567890',
  changeReason: 'Incorrect company details entered by the applicant',
};

export const applicationContactDetails: IContactDetails = {
  firstName: 'Joe',
  lastName: 'Bloggs',
  emailAddress: 'Joe.Bloggs@email.com',
  contactNumber: '012345678',
  changeReason: 'Incorrect company details entered by the applicant',
};

export const applicationProjectMilestones: IProjectMilestones = {
  startOnSiteDate: new Date('2024-01-01'),
  practicalCompletionDate: new Date('2024-12-31'),
  changeReason: 'Incorrect project milestones entered by the applicant',
};