import { z } from 'zod';

export const zUkBankAccountDetailsSchema = z.object({
  accountName: z.string().nonempty('Name on the account is required'),
  bankName: z.string().nonempty('Bank name is required'),
  branchName: z.string().nonempty('Branch name is required'),
  accountNumber: z
    .string()
    .nonempty('Account number is required')
    .transform(value => value.replace(/\D/gu, ''))
    .refine(
      value => value.length >= 6 && value.length <= 8,
      'Must be between 6 and 8 digits long'
    ),
  sortCode: z
    .string()
    .nonempty('Sort code is required')
    .transform(value => value.replace(/\D/gu, ''))
    .refine(value => value.length === 6, 'Must be 6 digits long'),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zBankAccountVerificationContactDetailsSchema = z.object({
  contactName: z.string().nonempty('Contact name is required'),
  contactNumber: z.string().nonempty('Contact number is required'),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zPtfsRequiredSchema = z.object({
  ptfsRequired: z.boolean({
    required_error: 'Ptfs required is required',
    invalid_type_error: 'Ptfs required is required',
  }),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zPtfsAmountSchema = z.object({
  ptfsAmount: z.coerce
    .number()
    .gt(0, { message: 'PTFS amount must be greater than 0' }),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zVatNumberSchema = z.object({
  vatNumber: z
    .string()
    .nonempty('VAT number is required')
    .regex(
      /^[A-Za-z]{2}[0-9]{9}$/,
      'Must be 2 letters followed by 9 digits, e.g. GB123456789'
    ),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zWhoseBankAccountSchema = z.object({
  whoseBankAccount: z.number({
    required_error: 'Whose bank account is required',
  }),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zAddressSchema = z.object({
  nameNumber: z.string().nonempty('Name / Number is required'),
  addressLine1: z.string().nonempty('Address line 1 is required'),
  addressLine2: z.string().optional(),
  city: z.string().nonempty('City is required'),
  county: z.string().optional(),
  postcode: z.string().nonempty('Postcode is required'),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zCompanyDetailsSchema = z.object({
  companyName: z.string().nonempty('Company name is required'),
  companyRegistrationNumber: z
    .string()
    .nonempty('Registration number is required'),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zContactDetailsSchema = z.object({
  firstName: z.string().nonempty('First name is required'),
  lastName: z.string().nonempty('Last name is required'),
  emailAddress: z.string().nonempty('Email address is required'),
  contactNumber: z.string().nonempty('Contact number is required'),
  additionalContactNumber: z.string().optional(),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zBuildingHeightSchema = z.object({
  buildingHeight: z.coerce
    .number()
    .gt(0, { message: 'Building height must be greater than 0' }),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zNumberOfStoreysSchema = z.object({
  numberOfStoreys: z.coerce
    .number()
    .gt(0, { message: 'Number of storeys must be greater than 0' }),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zTotalCladdingAreaSchema = z.object({
  totalCladdingArea: z.coerce
    .number()
    .gt(0, { message: 'Total cladding area must be greater than 0' }),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zLifeSafetyRiskAssessmentSchema = z.object({
  lifeSafetyRiskAssessment: z.coerce
    .number()
    .gt(0, { message: 'Life Safety risk assessment must be greater than 0' }),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zAssessmentTypeSchema = z.object({
  assessmentType: z.coerce
    .number()
    .gt(0, { message: 'Assessment type must be greater than 0' }),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zRecommendCladdingSchema = z.object({
  recommendCladding: z.coerce
    .number()
    .gt(0, { message: 'Recommend cladding must be greater than 0' }),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zApplicationFileSchema = z.object({
  fileId: z.string().nonempty('File Id is required'),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zBsrRegistrationCodeSchema = z.object({
  bsrRegistrationCode: z
    .string()
    .nonempty('BSR registration code is required')
    .regex(/^[A-ZA-Z0-9]{12}$/, 'BSR registration code must be 12 digits'),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zBuildingNameSchema = z.object({
  buildingName: z
    .string()
    .nonempty('Building name is required')
    .max(200, '200 characters is the maximum allowed.'),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zFraewSurveyCompanySchema = z.object({
  surveyCompany: z.object(
    {
      id: z.number(),
      name: z.string(),
    },
    {
      required_error: 'FRAEW survey company is required',
      invalid_type_error: 'FRAEW survey company is required',
    }
  ),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zFraewSurveyDateSchema = z.object({
  surveyDate: z.string().nonempty('FRAEW survey date is required'),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zFraewInstructionDateSchema = z.object({
  instructionDate: z.string().nonempty('FRAEW instruction date is required'),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zFraewReportAuthorSchema = z.object({
  reportAuthor: z
    .string()
    .nonempty('FRAEW report author is required')
    .max(150, '150 characters is the maximum allowed.'),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zFraewPeerReviewerSchema = z.object({
  peerReviewer: z
    .string()
    .nonempty('FRAEW peer reviewer is required')
    .max(150, '150 characters is the maximum allowed.'),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zFraewCostSchema = z.object({
  cost: z.coerce
    .number()
    .gt(0, { message: 'PTFS amount must be greater than 0' }),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});
