import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import {
  useGetPaymentRecommendationDetailsQuery,
  useUpdatePaymentRecommendationMutation,
} from 'api/application/paymentApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { useCurrentUserPermissions, useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { getUkDateStringFromJsonDateString } from 'util/AppUtils';

export const SendRecommendationBanner = ({
  recommendationSentAndReceived,
}: {
  recommendationSentAndReceived?: boolean;
}) => {
  const { applicationId, isVendorPaymentApprovalWaiting } =
    useApplicationContext();
  const [updatePaymentApproval, { isLoading, isError, error }] =
    useUpdatePaymentRecommendationMutation();
  const paymentDetails = useGetPaymentRecommendationDetailsQuery(applicationId);
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();
  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const doesUserHaveEditPermission = doesUserHaveSinglePermission(
    'payment-recommend.edit'
  );

  const sendRecommendation = async () => {
    if (!doesUserHaveEditPermission) {
      createErrorSnackbar('You do not have permission');
      return;
    }
    await updatePaymentApproval({
      applicationId,
    })
      .unwrap()
      .then(() => {
        createSuccessSnackbar('Payment Approved');
      });
  };

  return (
    <Box
      sx={{
        padding: 2,
        paddingTop: 3,
        border: '1px solid lightgray',
        borderRadius: 1,
        borderLeft: '5px solid black',
        borderLeftColor: 'black',
        bgcolor: grey[100],
      }}
    >
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <Typography variant="h3" fontWeight={800} fontSize="0.8em">
            {recommendationSentAndReceived || paymentDetails?.data?.isManual
              ? 'Payment Record Details'
              : 'Send Payment Recommendation to Homes England'}
          </Typography>
        </Grid>
        {paymentDetails?.data?.dateRecommendationApproved ? (
          <>
            <Grid xs={4} item>
              <Typography
                variant="body1"
                color={'grey.600'}
                fontSize="0.8em"
                fontWeight={600}
              >
                How much was this payment ?
              </Typography>
            </Grid>
            <Grid xs={4} item>
              <Typography
                variant="body1"
                color={'grey.600'}
                fontSize="0.8em"
                fontWeight={600}
              >
                Date of payment
              </Typography>
            </Grid>
            <Grid xs={3} item>
              <Typography
                variant="body1"
                color={'grey.600'}
                fontSize="0.8em"
                fontWeight={600}
              >
                Reference
              </Typography>
            </Grid>
            <Grid xs={1} item>
              {paymentDetails?.data?.isManual && (
                <FontAwesomeIcon
                  icon={solid('file-invoice')}
                  color="black"
                  size="2xl"
                />
              )}
            </Grid>
            <Grid xs={4} item>
              <Typography variant="h4" fontWeight={600} fontSize="1em">
                {`£${paymentDetails?.data?.paymentAmount?.toLocaleString()}`}
              </Typography>
            </Grid>
            <Grid xs={4} item>
              <Typography variant="h4" fontWeight={600} fontSize="1em">
                {paymentDetails?.data?.dateRecommendationSent
                  ? new Date(
                      paymentDetails?.data?.dateRecommendationSent
                    ).toLocaleDateString('en-GB')
                  : '-'}
              </Typography>
            </Grid>
            <Grid xs={4} item>
              <Typography variant="h4" fontWeight={600} fontSize="1em">
                {paymentDetails?.data?.reference}
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid xs={12} item>
              <Typography
                variant="body1"
                color={'grey.600'}
                fontSize="0.8em"
                fontWeight={600}
              >
                How much is this payment ?
              </Typography>
            </Grid>
            <Grid xs={12} item>
              <Typography variant="h4" fontWeight={600} fontSize="1em">
                {`£${paymentDetails?.data?.paymentAmount?.toLocaleString()}`}
              </Typography>
            </Grid>
          </>
        )}

        <Grid xs={12} item>
          {paymentDetails?.data?.recommendationSent ||
          recommendationSentAndReceived ? (
            <Box display="flex" alignItems="center">
              {paymentDetails?.data?.isManual ? (
                <FontAwesomeIcon
                  icon={solid('check-circle')}
                  color="black"
                  size="lg"
                />
              ) : null}
              <Box
                sx={{
                  marginLeft: 2,
                }}
              >
                <Typography fontSize="0.8em" fontWeight={600} color="black">
                  Record creation date
                </Typography>

                <Typography fontSize="0.6em" fontWeight={600} color="grey.700">
                  {paymentDetails?.data?.dateRecommendationSent
                    ? getUkDateStringFromJsonDateString(
                        paymentDetails?.data?.dateRecommendationSent.toString()
                      )
                    : getUkDateStringFromJsonDateString(new Date().toString())}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Button
              disabled={
                isVendorPaymentApprovalWaiting ||
                isLoading ||
                !doesUserHaveEditPermission
              }
              sx={{
                color: 'black',
                fontWeight: '600',
                fontSize: '0.8em',
                bgcolor: grey[100],
              }}
              variant="outlined"
              onClick={() => sendRecommendation()}
            >
              Recommend Payment
            </Button>
          )}
          <AlertForQueryErrorOrNull isError={isError} error={error} />
        </Grid>
      </Grid>
    </Box>
  );
};
