import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Grid, IconButton, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useGetApplicationInterventionQuery } from 'api/application';
import { PageTitle } from 'common/components/pageTitle';
import {
  SummaryItem,
  SummaryItemWithChip,
} from 'common/components/summaryItem';
import { EApplicationInternalStatusLanguage } from 'enums/EApplicationInternalStatus';
import { EApplicationStageLanguage } from 'enums/EApplicationStage';
import { EApplicationStatusLanguage } from 'enums/EApplicationStatus';
import { useEllipsisMenu, useModalState } from 'hooks';
import { AddButton } from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { ApplicationContactDetailsModal } from 'pages/applicationPage/common/summary/ApplicationContactDetailsModal';
import { ApplicationInterventionCategoryModal } from 'pages/applicationPage/common/summary/ApplicationInterventionCategoryModal';
import { ApplicationRagRatingModal } from 'pages/applicationPage/common/summary/ApplicationRagRatingModal';
import { ApplicationTimeLineModal } from 'pages/applicationPage/common/summary/ApplicationTimeLineModal';
import { ClosedReasonNotificationBanner } from 'pages/applicationPage/common/summary/ClosedReasonNotificationBanner';
import { InterventionCategoryNotificationBanner } from 'pages/applicationPage/common/summary/InterventionCategoryNotificationBanner';
import { EApplicationMenuType } from 'pages/applicationsPage/types/EApplicationMenuType';
import { IApplicationSummary } from 'types/applications/ApplicationTypes';
import { getUkDateStringFromJsonDateString } from 'util/AppUtils';

export const TitleAndSummarySection = ({
  data,
}: {
  data: IApplicationSummary;
}) => {
  const { applicationId } = useApplicationContext();
  const navigate = useNavigate();

  const { data: applicationInterventionData } =
    useGetApplicationInterventionQuery(applicationId);

  const {
    isShowing: isApplicationTimeLineModalShowing,
    showModal: showApplicationTimeLineModal,
    hideModal: hideApplicationTimeLineModal,
  } = useModalState();

  const {
    isShowing: isApplicationContactDetailsModalShowing,
    showModal: showApplicationContactDetailsModal,
    hideModal: hideApplicationContactDetailsModal,
  } = useModalState();

  const {
    isShowing: isApplicationInterventionCategoryModalShowing,
    showModal: showApplicationInterventionCategoryModal,
    hideModal: hideApplicationInterventionCategoryModal,
  } = useModalState();

  const {
    isShowing: isApplicationRagRatingModalShowing,
    showModal: showApplicationRagRatingModal,
    hideModal: hideApplicationRagRatingModal,
  } = useModalState();

  const { handleEllipsisClick, handleMenuClose, ellipsisMenuAnchor } =
    useEllipsisMenu();

  const handleMenuItemClick = (menuType: EApplicationMenuType) => {
    switch (menuType) {
      case EApplicationMenuType.Timeline:
        showApplicationTimeLineModal();
        break;
      case EApplicationMenuType.ContactDetails:
        showApplicationContactDetailsModal();
        break;
      case EApplicationMenuType.InterventionCategory:
        showApplicationInterventionCategoryModal();
        break;
      case EApplicationMenuType.RagRating:
        showApplicationRagRatingModal();
        break;
    }

    handleMenuClose();
  };

  return (
    <>
      <Grid container justifyContent="space-between" mb={3}>
        <Grid item>
          <Grid container>
            <Grid item p={1}>
              <IconButton
                size="large"
                color="inherit"
                name="Back to applications"
                onClick={() => {
                  navigate(-1);
                }}
                edge="start"
                sx={{
                  p: 1,
                  m: 0,
                }}
                aria-label="Back to applications"
              >
                <FontAwesomeIcon icon={faChevronLeft} size="xs" fixedWidth />
              </IconButton>
            </Grid>
            <Grid item pt={1.5}>
              <PageTitle title={`Application ${data.referenceNumber}`} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ ml: { xs: 3, lg: 0 } }}>
          <Grid container spacing={6} columnSpacing={3}>
            <SummaryItem label="Building Name" value={data.buildingName} />
            <SummaryItem label="Applicant" value={data.applicantName} />
            <SummaryItem
              label="Date Created"
              value={getUkDateStringFromJsonDateString(data.creationDate)}
            />
            <SummaryItem
              label="Date Updated"
              value={getUkDateStringFromJsonDateString(data.modifiedDate)}
            />
            <SummaryItem
              label="Stage"
              value={EApplicationStageLanguage[data.stage]}
            />
            <SummaryItem
              label="Status"
              value={EApplicationStatusLanguage[data.status]}
            />
            <SummaryItemWithChip
              label="Internal Status"
              value={EApplicationInternalStatusLanguage[data.internalStatus]}
            />
            <AddButton />

            <IconButton
              aria-label="edit"
              name="edit"
              onClick={event => handleEllipsisClick('', event)}
              sx={{
                marginTop: '3rem',
              }}
            >
              <MoreVertIcon fontSize="medium" />
            </IconButton>

            <Menu
              anchorEl={ellipsisMenuAnchor}
              open={Boolean(ellipsisMenuAnchor)}
              onClose={handleMenuItemClick}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem
                key={EApplicationMenuType.Timeline}
                onClick={() =>
                  handleMenuItemClick(EApplicationMenuType.Timeline)
                }
              >
                Timeline
              </MenuItem>
              <MenuItem
                key={EApplicationMenuType.ContactDetails}
                onClick={() =>
                  handleMenuItemClick(EApplicationMenuType.ContactDetails)
                }
              >
                Contact Details
              </MenuItem>
              <MenuItem
                key={EApplicationMenuType.InterventionCategory}
                onClick={() =>
                  handleMenuItemClick(EApplicationMenuType.InterventionCategory)
                }
              >
                Intervention Category
              </MenuItem>
              <MenuItem
                key={EApplicationMenuType.RagRating}
                onClick={() =>
                  handleMenuItemClick(EApplicationMenuType.RagRating)
                }
              >
                RAG Rating
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
        {data.closedReason && (
          <Grid container>
            <Grid item xs={12}>
              <ClosedReasonNotificationBanner
                closedReason={data.closedReason}
              />
            </Grid>
          </Grid>
        )}
        {!data.closedReason &&
          applicationInterventionData &&
          applicationInterventionData.interventionCategoryName && (
            <Grid container>
              <Grid item xs={12}>
                <InterventionCategoryNotificationBanner
                  interventionCategoryName={
                    applicationInterventionData.interventionCategoryName
                  }
                />
              </Grid>
            </Grid>
          )}
      </Grid>

      {isApplicationTimeLineModalShowing && (
        <ApplicationTimeLineModal
          onClose={() => hideApplicationTimeLineModal()}
        />
      )}

      {isApplicationContactDetailsModalShowing && (
        <ApplicationContactDetailsModal
          onClose={() => hideApplicationContactDetailsModal()}
        />
      )}

      {isApplicationInterventionCategoryModalShowing && (
        <ApplicationInterventionCategoryModal
          onClose={() => hideApplicationInterventionCategoryModal()}
        />
      )}

      {isApplicationRagRatingModalShowing && (
        <ApplicationRagRatingModal
          onClose={() => hideApplicationRagRatingModal()}
        />
      )}
    </>
  );
};
