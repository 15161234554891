import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { EQuestionType } from 'enums/EQuestionType';
import { useModalState } from 'hooks';
import { EApplicationDetailsQuestionMenuType } from 'pages/applicationPage/content/applicationDetails/ApplicationDetailsTypes';
import {
  ApplicationDetailsEditDialogs,
  IApplicationDetailsEditDialogs,
} from 'pages/applicationPage/content/applicationDetails/dialogs/ApplicationDetailsEditDialogs';
import { ViewQuestionAuditHistoryDialog } from 'pages/applicationPage/content/applicationDetails/dialogs/ViewQuestionAuditHistoryDialog';

export interface IApplicationDetailsDialogs {
  handleQuestionContextMenuButtonClick: (
    menuType: EApplicationDetailsQuestionMenuType,
    questionType: EQuestionType,
    currentValue?: string
  ) => void;
}

export const ApplicationDetailsDialogs = forwardRef((props, ref) => {
  const [selectedQuestionType, setSelectedQuestionType] =
    useState<EQuestionType | null>(null);
  const [currentValue, setCurrentValue] = useState<string | undefined>(
    undefined
  );

  const applicationDetailsEditDialogsRef =
    useRef<IApplicationDetailsEditDialogs>();

  const {
    isShowing: isViewQuestionAuditHistoryDialogShowing,
    showModal: showViewQuestionAuditHistoryDialog,
    hideModal: hideViewQuestionAuditHistoryDialog,
  } = useModalState();

  useImperativeHandle(ref, () => ({
    handleQuestionContextMenuButtonClick(
      menuType: EApplicationDetailsQuestionMenuType,
      questionType: EQuestionType,
      currentValue?: string
    ) {
      setSelectedQuestionType(questionType);
      setCurrentValue(currentValue);

      switch (menuType) {
        case EApplicationDetailsQuestionMenuType.EditItem:
          switch (questionType) {
            case EQuestionType.UkBankAccountDetails:
              applicationDetailsEditDialogsRef.current?.displayEditUkBankAccountDetailsDrawer();
              break;
            case EQuestionType.BankAccountVerificationContactDetails:
              applicationDetailsEditDialogsRef.current?.displayEditBankAccountVerificationContactDetailsDrawer();
              break;
            case EQuestionType.PtfsRequired:
              applicationDetailsEditDialogsRef.current?.displayEditPtfsRequiredDrawer();
              break;
            case EQuestionType.PtfsAmount:
              applicationDetailsEditDialogsRef.current?.displayEditPtfsAmountDrawer();
              break;
            case EQuestionType.VatNumber:
              applicationDetailsEditDialogsRef.current?.displayEditVatNumberDrawer();
              break;
            case EQuestionType.WhoseBankAccount:
              applicationDetailsEditDialogsRef.current?.displayEditWhoseBankAccountDrawer();
              break;
            case EQuestionType.BuildingAddress:
            case EQuestionType.DeveloperAddress:
            case EQuestionType.ResponsibleEntityAddress:
            case EQuestionType.RepresentativeAddress:
            case EQuestionType.FreeholderAddress:
              applicationDetailsEditDialogsRef.current?.displayEditAddressDrawer();
              break;
            case EQuestionType.ResponsibleEntityCompanyDetails:
            case EQuestionType.RepresentativeCompanyDetails:
            case EQuestionType.FreeholderCompanyDetails:
              applicationDetailsEditDialogsRef.current?.displayEditCompanyDetailsDrawer();
              break;
            case EQuestionType.ResponsibleEntityContactDetails:
            case EQuestionType.RepresentativeContactDetails:
            case EQuestionType.FreeholderContactDetails:
              applicationDetailsEditDialogsRef.current?.displayEditContactDetailsDrawer();
              break;
            case EQuestionType.BuildingHeight:
              applicationDetailsEditDialogsRef.current?.displayEditBuildingHeightDrawer();
              break;
            case EQuestionType.NumberOfStoreys:
              applicationDetailsEditDialogsRef.current?.displayEditNumberOfStoreysDrawer();
              break;
            case EQuestionType.TotalCladdingArea:
              applicationDetailsEditDialogsRef.current?.displayEditTotalCladdingAreaDrawer();
              break;
            case EQuestionType.LifeSafetyRiskAssessment:
              applicationDetailsEditDialogsRef.current?.displayEditLifeSafetyRiskAssessmentDrawer();
              break;
            case EQuestionType.AssessmentType:
              applicationDetailsEditDialogsRef.current?.displayEditAssessmentTypeDrawer();
              break;
            case EQuestionType.RecommendCladding:
              applicationDetailsEditDialogsRef.current?.displayEditRecommendCladdingDrawer();
              break;
            case EQuestionType.FireRiskSummary:
            case EQuestionType.FireRiskReport:
              applicationDetailsEditDialogsRef.current?.displayEditApplicationFileDrawer();
              break;
            case EQuestionType.BsrRegistrationCode:
              applicationDetailsEditDialogsRef.current?.displayEditBsrRegistrationCodeDrawer();
              break;
            case EQuestionType.BuildingName:
              applicationDetailsEditDialogsRef.current?.displayEditBuildingNameDrawer();
              break;
            case EQuestionType.FraewSurveyCompany:
              applicationDetailsEditDialogsRef.current?.displayEditFraewSurveyCompanyDrawer();
              break;
            case EQuestionType.FraewSurveyDate:
              applicationDetailsEditDialogsRef.current?.displayEditFraewSurveyDateDrawer();
              break;
            case EQuestionType.FraewInstructionDate:
              applicationDetailsEditDialogsRef.current?.displayEditFraewInstructionDateDrawer();
              break;
            case EQuestionType.FraewReportAuthor:
              applicationDetailsEditDialogsRef.current?.displayEditFraewReportAuthorDrawer();
              break;
            case EQuestionType.FraewPeerReviewer:
              applicationDetailsEditDialogsRef.current?.displayEditFraewPeerReviewerDrawer();
              break;
            case EQuestionType.FraewCost:
              applicationDetailsEditDialogsRef.current?.displayEditFraewCostDrawer();
              break;
            case EQuestionType.ProjectMilestones:
              applicationDetailsEditDialogsRef.current?.displayEditProjectMilestonesDrawer();
              break;
            default:
              throw new Error('EQuestionType not supported');
          }

          break;
        case EApplicationDetailsQuestionMenuType.ViewHistory:
          showViewQuestionAuditHistoryDialog();
          break;
        default:
          throw new Error('ApplicationDetailsQuestionMenuType not supported');
      }
    },
  }));

  return (
    <>
      <ApplicationDetailsEditDialogs
        ref={applicationDetailsEditDialogsRef}
        questionType={
          selectedQuestionType !== null ? selectedQuestionType : undefined
        }
        currentValue={currentValue}
      />

      {isViewQuestionAuditHistoryDialogShowing &&
        selectedQuestionType !== null && (
          <ViewQuestionAuditHistoryDialog
            questionType={selectedQuestionType}
            onClose={hideViewQuestionAuditHistoryDialog}
          />
        )}
    </>
  );
});
