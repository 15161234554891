import { Button } from '@mui/material';
import { useApprovePaymentRequestMutation } from 'api/application/paymentRequestApi';
import { useCurrentUserPermissions, useLocalSnackbar } from 'hooks';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';

interface IRecommendPaymentRequestModalProps {
  applicationId: string;
  paymentRequestId: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export function ApprovePaymentRequestModal({
  applicationId,
  paymentRequestId,
  onConfirm,
  onCancel,
}: IRecommendPaymentRequestModalProps) {
  const [approvePaymentRequest, approvePaymentRequestStatus] = useApprovePaymentRequestMutation();

  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const doesUserHaveEditPermission = doesUserHaveSinglePermission(
    'payment-release.edit'
  );

  const handleApprovePaymentRequest = async () => {
    if (!doesUserHaveEditPermission) {
      createErrorSnackbar('You do not have permission');
      onCancel();
      return;
    }

    await approvePaymentRequest({
      applicationId,
      paymentRequestId,
    })
      .unwrap()
      .then(() => {
        createSuccessSnackbar('Payment Request Approved');
        onConfirm();
      })
      .catch(error => {
        createErrorSnackbar(error);
      });
  };

  return (
    <StyledDialog open>
      <StyledDialogTitle>
        Are you sure you want to approve this payment request?
      </StyledDialogTitle>
      <StyledDialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleApprovePaymentRequest} disabled={approvePaymentRequestStatus.isLoading}>
          Approve Payment
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}
