import {
  TypedUseQueryHookResult,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/dist/query/react';
import {
  IGetPaymentRequestReferralsResponse,
  IPaymentRequestReferralRequest,
} from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import {
  IGetScheduleOfWorksReferralsResponse,
  IScheduleOfWorksReferralRequest,
} from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/ScheduleOfWorksTypes';
import { IPaginationResponse } from 'types/PaginationResponse';
import {
  IApplicationDetailsCategories,
  IApplicationDetailsFileParams,
  IApplicationSection,
  IApplicationSectionQueryParams,
} from 'types/applications/ApplicationDetailTypes';
import {
  IApplicationEligibilityCategories,
  IApplicationEligibilityReferral,
} from 'types/applications/ApplicationEligibilityTypes';
import { IApplicationGrantFundingCategories } from 'types/applications/ApplicationGrantFundingTypes';
import {
  IApplicationContactDetails,
  IApplicationGridListItem,
  IApplicationIntervention,
  IApplicationInterventionCategories,
  IApplicationJourneyTimeline,
  IApplicationKpis,
  IApplicationRagRating,
  IApplicationRagRatings,
  IApplicationSummary,
  ICloseApplicationReasons,
  IDeleteApplicationIntervention,
  IGetApplicationsPaginatedQuery,
  IPutCloseApplicationParams,
} from 'types/applications/ApplicationTypes';
import { QueryTags, variationQueryTagsArray } from 'util/ApiUtils';
import { sortByOrderAsc } from 'util/AppUtils';
import { handleFileDownloadFromWithinRtkQuery } from 'util/FileUtils';
import {
  customPrepareHeaders,
  getUrlSearchParamFromQueryData,
} from 'util/QueryUtils';

const baseUrl = `${process.env.REACT_APP_API_URL}/api/application`;

const getPaginatedApplicationsString = (
  query: IGetApplicationsPaginatedQuery
) => {
  const queryParamsStr = getUrlSearchParamFromQueryData({
    skip: query.skip,
    take: query.take,
    sortBy: query.sortBy.toString(),
    sortDirection: query.sortDirection.toString(),
    searchValue: query.searchValue,
  });

  queryParamsStr.append('showClosed', `${query.showClosed}`);

  if (query.stage.length > 0) {
    for (const stage of query.stage) {
      queryParamsStr.append('stage', `${stage}`);
    }
  }

  if (query.status.length > 0) {
    for (const status of query.status) {
      queryParamsStr.append('status', `${status}`);
    }
  }

  if (query.internalStatus.length > 0) {
    for (const internalStatus of query.internalStatus) {
      queryParamsStr.append('internalStatus', `${internalStatus}`);
    }
  }

  return `${
    process.env.REACT_APP_API_URL
  }/api/application/grid?${queryParamsStr.toString()}`;
};

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: customPrepareHeaders,
  credentials: 'include',
});

export const applicationApi = createApi({
  reducerPath: 'applicationApi',
  baseQuery,
  tagTypes: [
    QueryTags.ApplicationDetails,
    QueryTags.ApplicationStatus,
    QueryTags.ApplicationIntervention,
    QueryTags.ApplicationRagRating,
    QueryTags.ApplicationEligibility,
    QueryTags.ApplicationGrantFunding,
    QueryTags.PEPSanctions,
    QueryTags.ApplicationDocuments,
    QueryTags.ApplicationCommunications,
    QueryTags.Tasks,
    QueryTags.ApplicationVendorDetails,
    QueryTags.ApplicationSignatories,
    QueryTags.ApplicationPaymentRecommendationDetails,
    QueryTags.ApplicationPaymentReleaseDetails,
    QueryTags.ApplicationSupportList,
    QueryTags.ApplicationSupportTicket,
    QueryTags.ApplicationDutyOfCareDetails,
    QueryTags.ApplicationProjectPrepOverview,
    QueryTags.ApplicationProjectPrepList,
    QueryTags.ApplicationProjectPrepProgressReport,
    QueryTags.ApplicationWorksPackageOverview,
    QueryTags.ApplicationWorksPackageChecks,
    QueryTags.ApplicationWorksPackageCladding,
    QueryTags.ApplicationWorksPackageCostsSchedule,
    QueryTags.ApplicationWorksPackageReferralList,
    QueryTags.ApplicationWorksPackageReferral,
    QueryTags.ApplicationLiveProjectOverview,
    QueryTags.ApplicationLiveProjectList,
    QueryTags.ApplicationScheduleOfWorks,
    QueryTags.ApplicationScheduleOfWorksOverview,
    QueryTags.ApplicationPaymentRequest,
    QueryTags.ApplicationPaymentRequestOverview,
    QueryTags.ApplicationClosingPaymentRequest,
    QueryTags.ApplicationClosingPaymentRequestOverview,
    QueryTags.ApplicationWorksPackageDeedDetails,
    QueryTags.ApplicationWorksPackageBuildingDetails,
    QueryTags.ApplicationOnHoldLatest,
    QueryTags.ApplicationDetailsEdit,
    ...variationQueryTagsArray,
  ],
  endpoints: builder => ({
    getPaginatedApplications: builder.query<
      IPaginationResponse<IApplicationGridListItem>,
      IGetApplicationsPaginatedQuery
    >({
      providesTags: [QueryTags.ApplicationStatus],
      query: query => {
        return getPaginatedApplicationsString(query);
      },
    }),
    getApplicationsKpis: builder.query<IApplicationKpis, void>({
      providesTags: [QueryTags.ApplicationStatus],
      query: _ => 'kpis',
    }),
    getApplicationSummary: builder.query<IApplicationSummary, string>({
      providesTags: [QueryTags.ApplicationStatus],
      query: applicationId => `/${applicationId}/summary`,
    }),
    getApplicationContactDetails: builder.query<
      IApplicationContactDetails,
      string
    >({
      providesTags: [QueryTags.ApplicationDetails],
      query: applicationId => `/${applicationId}/applicant/contactDetails`,
    }),
    getApplicationCategories: builder.query<
      IApplicationDetailsCategories,
      string
    >({
      query: applicationId => `/${applicationId}/details`,
      transformResponse: (response: IApplicationDetailsCategories) => {
        // Sort data before it hits the cache

        // First, sort top level categories
        response.categories.sort(sortByOrderAsc);

        // Then iterate each category and sort child items
        for (const category of response.categories) {
          category.detailItems.sort(sortByOrderAsc);
        }

        return response;
      },
    }),
    getApplicationDetailsSection: builder.query<
      IApplicationSection,
      IApplicationSectionQueryParams
    >({
      providesTags: [QueryTags.ApplicationDetails],
      query: ({ applicationId, sectionId }) =>
        `/${applicationId}/section/${sectionId}`,
    }),
    applicationDetailsFileDownload: builder.query<
      null,
      IApplicationDetailsFileParams
    >({
      queryFn: async (
        { applicationId, fileId, fileName },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const result = await baseQuery({
          url: `${applicationId}/file/${fileId}`,
          responseHandler: response => response.blob(),
        });

        if (result.error) {
          return { error: result.error };
        }

        return handleFileDownloadFromWithinRtkQuery(
          fileName,
          result.data as Blob
        );
      },
    }),
    getCloseApplicationReasons: builder.query<
      Array<ICloseApplicationReasons>,
      void
    >({
      query: () => `close/reasons`,
    }),
    closeApplication: builder.mutation<void, IPutCloseApplicationParams>({
      invalidatesTags: [QueryTags.ApplicationStatus],
      query: ({ applicationId, ...body }) => {
        return {
          url: `${applicationId}/close`,
          method: 'PUT',
          body,
        };
      },
    }),
    getJourneyTimeline: builder.query<
      Array<IApplicationJourneyTimeline>,
      string
    >({
      query: applicationId => `/${applicationId}/journeyTimeline`,
    }),
    getInterventionCategories: builder.query<
      Array<IApplicationInterventionCategories>,
      void
    >({
      query: () =>
        `${process.env.REACT_APP_API_URL}/api/intervention/categories`,
    }),
    getApplicationIntervention: builder.query<
      IApplicationIntervention | null,
      string
    >({
      providesTags: [QueryTags.ApplicationIntervention],
      query: applicationId => `/${applicationId}/intervention`,
    }),
    addApplicationIntervention: builder.mutation<
      void,
      IApplicationIntervention
    >({
      invalidatesTags: [QueryTags.ApplicationIntervention],
      query: ({ applicationId, ...body }) => {
        return {
          url: `${applicationId}/intervention`,
          method: 'POST',
          body,
        };
      },
    }),
    deleteApplicationIntervention: builder.mutation<
      void,
      IDeleteApplicationIntervention
    >({
      invalidatesTags: [QueryTags.ApplicationIntervention],
      query: ({ applicationId, ...body }) => {
        return {
          url: `${applicationId}/intervention/remove`,
          method: 'POST',
          body,
        };
      },
    }),
    getRagRatings: builder.query<Array<IApplicationRagRatings>, void>({
      query: () => `${process.env.REACT_APP_API_URL}/api/ragRatings`,
    }),
    getApplicationRagRating: builder.query<
      IApplicationRagRating | null,
      string
    >({
      providesTags: [QueryTags.ApplicationRagRating],
      query: applicationId => `/${applicationId}/ragRating`,
    }),
    updateApplicationRagRating: builder.mutation<void, IApplicationRagRating>({
      invalidatesTags: [QueryTags.ApplicationRagRating],
      query: ({ applicationId, ...body }) => {
        return {
          url: `${applicationId}/ragRating`,
          method: 'PUT',
          body,
        };
      },
    }),
  }),
});

// If we want to type any of the auto generated api hooks then `ReturnType` isn't enough
// Therefore we need to use `TypedUseQueryHookResult` passing in the relevant params to match the definitiion of the query
// More details: https://github.com/reduxjs/redux-toolkit/pull/2276
export type GetApplicationCategoriesQueryResult = TypedUseQueryHookResult<
  IApplicationDetailsCategories,
  string,
  typeof baseQuery
>;

export type GetApplicationEligibilityCategoriesQueryResult =
  TypedUseQueryHookResult<
    IApplicationEligibilityCategories,
    string,
    typeof baseQuery
  >;

export type GetApplicationEligibilityReferralsQueryResult =
  TypedUseQueryHookResult<
    Array<IApplicationEligibilityReferral>,
    string,
    typeof baseQuery
  >;

export type GetApplicationGrantFundingCategoriesQueryResult =
  TypedUseQueryHookResult<
    IApplicationGrantFundingCategories,
    string,
    typeof baseQuery
  >;

export type GetScheduleOfWorksReferralsQueryResult = TypedUseQueryHookResult<
  IGetScheduleOfWorksReferralsResponse,
  IScheduleOfWorksReferralRequest,
  typeof baseQuery
>;

export type GetPaymentRequestReferralsQueryResult = TypedUseQueryHookResult<
  IGetPaymentRequestReferralsResponse,
  IPaymentRequestReferralRequest,
  typeof baseQuery
>;

export const {
  useGetPaginatedApplicationsQuery,
  useGetApplicationsKpisQuery,
  useGetApplicationSummaryQuery,
  useGetApplicationContactDetailsQuery,
  useGetApplicationCategoriesQuery,
  useGetApplicationDetailsSectionQuery,
  useLazyApplicationDetailsFileDownloadQuery,
  useGetCloseApplicationReasonsQuery,
  useCloseApplicationMutation,
  useGetJourneyTimelineQuery,
  useGetInterventionCategoriesQuery,
  useGetApplicationInterventionQuery,
  useAddApplicationInterventionMutation,
  useGetRagRatingsQuery,
  useGetApplicationRagRatingQuery,
  useUpdateApplicationRagRatingMutation,
  useDeleteApplicationInterventionMutation,
} = applicationApi;
