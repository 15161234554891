import { useState } from 'react';
import styled from '@emotion/styled';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, IconButton, LinearProgress, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

export type FileUploadInputSelectProps = {
  id: string;
  imageButton?: boolean;
  accept: string;
  label?: string;
  subLabel?: string;
  dropLabel?: string;
  width?: string;
  height?: string;
  backgroundColor?: string;
  image?: {
    url: string;
    imageStyle?: {
      width?: string;
      height?: string;
    };
  };
  inputName: string;
  onFileUpload: (file: File) => void;
  onFileDelete?: () => void;
  isUploading?: boolean;
  uploadingMsg?: string;
  uploaded?: boolean;
  disabled?: boolean;
};

type StyledProps = {
  isDragOver?: boolean;
  height: string;
};

const StyledLabel = styled('label')<StyledProps>`
  cursor: pointer;
  text-align: center;
  color: #757575;
  &:hover p,
  &:hover svg {
    opacity: 1;
  }
  & p,
  svg {
    opacity: 0.8;
  }
  ${props =>
    props.isDragOver
      ? `
    & p, svg {
      opacity: 1;
    }
    `
      : ''}

  ${props => `height: ${props.height}`}
`;

export const FileUploadInputSelect = ({
  accept,
  label = 'Click to upload or drag and drop PDF (max. 30MB)',
  subLabel,
  dropLabel = 'Drop file here',
  width = '100%',
  height = '100px',
  backgroundColor = '#fff',
  inputName,
  onFileUpload,
  onFileDelete,
  id,
  isUploading = false,
  uploadingMsg = 'Uploading. Please wait...',
  uploaded = undefined,
  disabled = false,
}: FileUploadInputSelectProps) => {
  const [labelText, setLabelText] = useState<string>(label);
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string | null>(null);

  const form = useFormContext();
  const register = form?.register;

  const stopDefaults = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null && event.target?.files?.length > 0) {
      setFileName(event.target.files[0].name);
      onFileUpload(event.target.files[0]);
    }
  };

  const handleOnDrop = (event: React.DragEvent<HTMLElement>) => {
    stopDefaults(event);
    setLabelText(label);
    setIsDragOver(false);
    setFileName(event.dataTransfer.files[0].name);
    onFileUpload(event.dataTransfer.files[0]);
  };

  return (
    <Box
      sx={{
        borderRadius: '10px',
        border: '3px dashed',
        overflow: 'hidden',
        borderColor: 'grey.300',
      }}
      width={width}
    >
      {isUploading ? (
        <Box p={3}>
          <LinearProgress />
          <Typography variant="body1" color="grey.400" my={3}>
            {uploadingMsg}
          </Typography>
        </Box>
      ) : null}

      {!isUploading && uploaded !== false && fileName ? (
        <Box p={2}>
          {`File to upload: ${fileName}`}

          {onFileDelete ? (
            <IconButton
              size="small"
              onClick={() => {
                setFileName(null);
                onFileDelete();
              }}
              aria-label="Delete file"
              name="Delete file"
              disabled={disabled}
            >
              <FontAwesomeIcon icon={faTrash} fontSize={'0.85em'} />
            </IconButton>
          ) : null}
        </Box>
      ) : (
        <>
          <Box sx={isUploading ? { display: 'none' } : null}>
            <input
              onChange={handleOnChange}
              accept={accept}
              style={{
                display: 'none',
              }}
              id={`file-select-${id}`}
              data-testid={`file-select-${id}`}
              type="file"
              disabled={disabled}
            />
            <StyledLabel
              htmlFor={`file-select-${id}`}
              onDragEnter={(e: React.DragEvent) => {
                stopDefaults(e);
                setIsDragOver(true);
                setLabelText(dropLabel);
              }}
              onDragLeave={(e: React.DragEvent) => {
                stopDefaults(e);
                setIsDragOver(false);
                setLabelText(label);
              }}
              onDragOver={stopDefaults}
              onDrop={handleOnDrop}
              isDragOver={isDragOver}
              height={height}
            >
              <Box
                bgcolor={backgroundColor}
                sx={{
                  pointerEvents: 'none',
                }}
              >
                <Box role="button">
                  <CloudUploadIcon fontSize="large" />
                  <Typography role="button">{labelText}</Typography>
                  {subLabel && (
                    <Typography fontSize="0.8rem" textAlign="center">
                      {subLabel}
                    </Typography>
                  )}
                </Box>
              </Box>
            </StyledLabel>
          </Box>
        </>
      )}

      {register ? <input type="hidden" {...register(inputName)} /> : null}
    </Box>
  );
};
