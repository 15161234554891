import { forwardRef, useImperativeHandle } from 'react';
import { EQuestionType } from 'enums/EQuestionType';
import { useModalState } from 'hooks';
import { EditAddressDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditAddressDrawer';
import { EditApplicationFileDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditApplicationFileDrawer';
import { EditAssessmentTypeDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditAssessmentTypeDrawer';
import { EditBankAccountVerificationContactDetailsDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditBankAccountVerificationContactDetailsDrawer';
import { EditBsrRegistrationCodeDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditBsrRegistrationCodeDrawer';
import { EditBuildingHeightDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditBuildingHeightDrawer';
import { EditBuildingNameDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditBuildingNameDrawer';
import { EditCompanyDetailsDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditCompanyDetailsDrawer';
import { EditContactDetailsDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditContactDetailsDrawer';
import { EditFraewCostDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditFraewCostDrawer';
import { EditFraewInstructionDateDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditFraewInstructionDateDrawer';
import { EditFraewPeerReviewerDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditFraewPeerReviewerDrawer';
import { EditFraewReportAuthorDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditFraewReportAuthorDrawer';
import { EditFraewSurveyCompanyDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditFraewSurveyCompanyDrawer';
import { EditFraewSurveyDateDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditFraewSurveyDateDrawer';
import { EditLifeSafetyRiskAssessmentDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditLifeSafetyRiskAssessmentDrawer';
import { EditNumberOfStoreysDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditNumberOfStoreysDrawer';
import { EditProjectMilestonesDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditProjectMilestonesDrawer';
import { EditPtfsAmountDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditPtfsAmountDrawer';
import { EditPtfsRequiredDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditPtfsRequiredDrawer';
import { EditRecommendCladdingDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditRecommendCladdingDrawer';
import { EditTotalCladdingAreaDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditTotalCladdingAreaDrawer';
import { EditUkBankAccountDetailsDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditUkBankAccountDetailsDrawer';
import { EditVatNumberDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditVatNumberDrawer';
import { EditWhoseBankAccountDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditWhoseBankAccountDrawer';

export interface IApplicationDetailsEditDialogs {
  displayEditUkBankAccountDetailsDrawer: () => void;
  displayEditBankAccountVerificationContactDetailsDrawer: () => void;
  displayEditPtfsRequiredDrawer: () => void;
  displayEditPtfsAmountDrawer: () => void;
  displayEditVatNumberDrawer: () => void;
  displayEditWhoseBankAccountDrawer: () => void;
  displayEditAddressDrawer: () => void;
  displayEditCompanyDetailsDrawer: () => void;
  displayEditContactDetailsDrawer: () => void;
  displayEditBuildingHeightDrawer: () => void;
  displayEditNumberOfStoreysDrawer: () => void;
  displayEditTotalCladdingAreaDrawer: () => void;
  displayEditLifeSafetyRiskAssessmentDrawer: () => void;
  displayEditAssessmentTypeDrawer: () => void;
  displayEditRecommendCladdingDrawer: () => void;
  displayEditApplicationFileDrawer: () => void;
  displayViewQuestionAuditHistoryDialog: () => void;
  displayEditBsrRegistrationCodeDrawer: () => void;
  displayEditBuildingNameDrawer: () => void;
  displayEditFraewSurveyCompanyDrawer: () => void;
  displayEditFraewSurveyDateDrawer: () => void;
  displayEditFraewInstructionDateDrawer: () => void;
  displayEditFraewReportAuthorDrawer: () => void;
  displayEditFraewPeerReviewerDrawer: () => void;
  displayEditFraewCostDrawer: () => void;
  displayEditProjectMilestonesDrawer: () => void;
}

export interface IApplicationDetailsEditDialogProps {
  questionType?: EQuestionType;
  currentValue?: string;
}

export const ApplicationDetailsEditDialogs = forwardRef(
  (props: IApplicationDetailsEditDialogProps, ref) => {
    const {
      isShowing: isEditUkBankAccountDetailsDrawerShowing,
      showModal: showEditUkBankAccountDetailsDrawer,
      hideModal: hideEditUkBankAccountDetailsDrawer,
    } = useModalState();

    const {
      isShowing: isEditBankAccountVerificationContactDetailsDrawerShowing,
      showModal: showEditBankAccountVerificationContactDetailsDrawer,
      hideModal: hideEditBankAccountVerificationContactDetailsDrawer,
    } = useModalState();

    const {
      isShowing: isEditPtfsRequiredDrawerShowing,
      showModal: showEditPtfsRequiredDrawer,
      hideModal: hideEditPtfsRequiredDrawer,
    } = useModalState();

    const {
      isShowing: isEditPtfsAmountDrawerShowing,
      showModal: showEditPtfsAmountDrawer,
      hideModal: hideEditPtfsAmountDrawer,
    } = useModalState();

    const {
      isShowing: isEditVatNumberDrawerShowing,
      showModal: showEditVatNumberDrawer,
      hideModal: hideEditVatNumberDrawer,
    } = useModalState();

    const {
      isShowing: isEditWhoseBankAccountDrawerShowing,
      showModal: showEditWhoseBankAccountDrawer,
      hideModal: hideEditWhoseBankAccountDrawer,
    } = useModalState();

    const {
      isShowing: isEditAddressDrawerShowing,
      showModal: showEditAddressDrawer,
      hideModal: hideEditAddressDrawer,
    } = useModalState();

    const {
      isShowing: isEditCompanyDetailsDrawerShowing,
      showModal: showEditCompanyDetailsDrawer,
      hideModal: hideEditCompanyDetailsDrawer,
    } = useModalState();

    const {
      isShowing: isEditContactDetailsDrawerShowing,
      showModal: showEditContactDetailsDrawer,
      hideModal: hideEditContactDetailsDrawer,
    } = useModalState();

    const {
      isShowing: isEditBuildingHeightDrawerShowing,
      showModal: showEditBuildingHeightDrawer,
      hideModal: hideEditBuildingHeightDrawer,
    } = useModalState();

    const {
      isShowing: isEditNumberOfStoreysDrawerShowing,
      showModal: showEditNumberOfStoreysDrawer,
      hideModal: hideEditNumberOfStoreysDrawer,
    } = useModalState();

    const {
      isShowing: isEditTotalCladdingAreaDrawerShowing,
      showModal: showEditTotalCladdingAreaDrawer,
      hideModal: hideEditTotalCladdingAreaDrawer,
    } = useModalState();

    const {
      isShowing: isEditLifeSafetyRiskAssessmentDrawerShowing,
      showModal: showEditLifeSafetyRiskAssessmentDrawer,
      hideModal: hideEditLifeSafetyRiskAssessmentDrawer,
    } = useModalState();

    const {
      isShowing: isEditAssessmentTypeDrawerShowing,
      showModal: showEditAssessmentTypeDrawer,
      hideModal: hideEditAssessmentTypeDrawer,
    } = useModalState();

    const {
      isShowing: isEditRecommendCladdingDrawerShowing,
      showModal: showEditRecommendCladdingDrawer,
      hideModal: hideEditRecommendCladdingDrawer,
    } = useModalState();

    const {
      isShowing: isEditApplicationFileDrawerShowing,
      showModal: showEditApplicationFileDrawer,
      hideModal: hideEditApplicationFileDrawer,
    } = useModalState();

    const {
      isShowing: isEditBsrRegistrationCodeDrawerShowing,
      showModal: showEditBsrRegistrationCodeDrawer,
      hideModal: hideEditBsrRegistrationCodeDrawer,
    } = useModalState();

    const {
      isShowing: isEditBuildingNameDrawerShowing,
      showModal: showEditBuildingNameDrawer,
      hideModal: hideEditBuildingNameDrawer,
    } = useModalState();

    const {
      isShowing: isEditFraewSurveyCompanyDrawerShowing,
      showModal: showEditFraewSurveyCompanyDrawer,
      hideModal: hideEditFraewSurveyCompanyDrawer,
    } = useModalState();

    const {
      isShowing: isEditFraewSurveyDateDrawerShowing,
      showModal: showEditFraewSurveyDateDrawer,
      hideModal: hideEditFraewSurveyDateDrawer,
    } = useModalState();

    const {
      isShowing: isEditFraewInstructionDateDrawerShowing,
      showModal: showEditFraewInstructionDateDrawer,
      hideModal: hideEditFraewInstructionDateDrawer,
    } = useModalState();

    const {
      isShowing: isEditFraewReportAuthorDrawerShowing,
      showModal: showEditFraewReportAuthorDrawer,
      hideModal: hideEditFraewReportAuthorDrawer,
    } = useModalState();

    const {
      isShowing: isEditFraewPeerReviewerDrawerShowing,
      showModal: showEditFraewPeerReviewerDrawer,
      hideModal: hideEditFraewPeerReviewerDrawer,
    } = useModalState();

    const {
      isShowing: isEditFraewCostDrawerShowing,
      showModal: showEditFraewCostDrawer,
      hideModal: hideEditFraewCostDrawer,
    } = useModalState();

    const {
      isShowing: isEditProjectMilestonesDrawerShowing,
      showModal: showEditProjectMilestonesDrawer,
      hideModal: hideEditProjectMilestonesDrawer,
    } = useModalState();

    useImperativeHandle(ref, () => ({
      displayEditUkBankAccountDetailsDrawer() {
        showEditUkBankAccountDetailsDrawer();
      },
      displayEditBankAccountVerificationContactDetailsDrawer() {
        showEditBankAccountVerificationContactDetailsDrawer();
      },
      displayEditPtfsRequiredDrawer() {
        showEditPtfsRequiredDrawer();
      },
      displayEditPtfsAmountDrawer() {
        showEditPtfsAmountDrawer();
      },
      displayEditVatNumberDrawer() {
        showEditVatNumberDrawer();
      },
      displayEditWhoseBankAccountDrawer() {
        showEditWhoseBankAccountDrawer();
      },
      displayEditAddressDrawer() {
        showEditAddressDrawer();
      },
      displayEditCompanyDetailsDrawer() {
        showEditCompanyDetailsDrawer();
      },
      displayEditContactDetailsDrawer() {
        showEditContactDetailsDrawer();
      },
      displayEditBuildingHeightDrawer() {
        showEditBuildingHeightDrawer();
      },
      displayEditNumberOfStoreysDrawer() {
        showEditNumberOfStoreysDrawer();
      },
      displayEditTotalCladdingAreaDrawer() {
        showEditTotalCladdingAreaDrawer();
      },
      displayEditLifeSafetyRiskAssessmentDrawer() {
        showEditLifeSafetyRiskAssessmentDrawer();
      },
      displayEditAssessmentTypeDrawer() {
        showEditAssessmentTypeDrawer();
      },
      displayEditRecommendCladdingDrawer() {
        showEditRecommendCladdingDrawer();
      },
      displayEditApplicationFileDrawer() {
        showEditApplicationFileDrawer();
      },
      displayEditBsrRegistrationCodeDrawer() {
        showEditBsrRegistrationCodeDrawer();
      },
      displayEditBuildingNameDrawer() {
        showEditBuildingNameDrawer();
      },
      displayEditFraewSurveyCompanyDrawer() {
        showEditFraewSurveyCompanyDrawer();
      },
      displayEditFraewSurveyDateDrawer() {
        showEditFraewSurveyDateDrawer();
      },
      displayEditFraewInstructionDateDrawer() {
        showEditFraewInstructionDateDrawer();
      },
      displayEditFraewReportAuthorDrawer() {
        showEditFraewReportAuthorDrawer();
      },
      displayEditFraewPeerReviewerDrawer() {
        showEditFraewPeerReviewerDrawer();
      },
      displayEditFraewCostDrawer() {
        showEditFraewCostDrawer();
      },
      displayEditProjectMilestonesDrawer() {
        showEditProjectMilestonesDrawer();
      },
    }));

    return (
      <>
        {isEditUkBankAccountDetailsDrawerShowing && (
          <EditUkBankAccountDetailsDrawer
            onSuccess={() => {
              hideEditUkBankAccountDetailsDrawer();
            }}
            onClose={() => {
              hideEditUkBankAccountDetailsDrawer();
            }}
          />
        )}

        {isEditBankAccountVerificationContactDetailsDrawerShowing && (
          <EditBankAccountVerificationContactDetailsDrawer
            onSuccess={() => {
              hideEditBankAccountVerificationContactDetailsDrawer();
            }}
            onClose={() => {
              hideEditBankAccountVerificationContactDetailsDrawer();
            }}
          />
        )}

        {isEditPtfsRequiredDrawerShowing && (
          <EditPtfsRequiredDrawer
            onSuccess={() => {
              hideEditPtfsRequiredDrawer();
            }}
            onClose={() => {
              hideEditPtfsRequiredDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditPtfsAmountDrawerShowing && (
          <EditPtfsAmountDrawer
            onSuccess={() => {
              hideEditPtfsAmountDrawer();
            }}
            onClose={() => {
              hideEditPtfsAmountDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditVatNumberDrawerShowing && (
          <EditVatNumberDrawer
            onSuccess={() => {
              hideEditVatNumberDrawer();
            }}
            onClose={() => {
              hideEditVatNumberDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditWhoseBankAccountDrawerShowing && (
          <EditWhoseBankAccountDrawer
            onSuccess={() => {
              hideEditWhoseBankAccountDrawer();
            }}
            onClose={() => {
              hideEditWhoseBankAccountDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditAddressDrawerShowing && props.questionType && (
          <EditAddressDrawer
            questionType={props.questionType}
            onSuccess={() => {
              hideEditAddressDrawer();
            }}
            onClose={() => {
              hideEditAddressDrawer();
            }}
          />
        )}

        {isEditCompanyDetailsDrawerShowing && props.questionType && (
          <EditCompanyDetailsDrawer
            questionType={props.questionType}
            onSuccess={() => {
              hideEditCompanyDetailsDrawer();
            }}
            onClose={() => {
              hideEditCompanyDetailsDrawer();
            }}
          />
        )}

        {isEditContactDetailsDrawerShowing && props.questionType && (
          <EditContactDetailsDrawer
            questionType={props.questionType}
            onSuccess={() => {
              hideEditContactDetailsDrawer();
            }}
            onClose={() => {
              hideEditContactDetailsDrawer();
            }}
          />
        )}

        {isEditBuildingHeightDrawerShowing && (
          <EditBuildingHeightDrawer
            onSuccess={() => {
              hideEditBuildingHeightDrawer();
            }}
            onClose={() => {
              hideEditBuildingHeightDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditNumberOfStoreysDrawerShowing && (
          <EditNumberOfStoreysDrawer
            onSuccess={() => {
              hideEditNumberOfStoreysDrawer();
            }}
            onClose={() => {
              hideEditNumberOfStoreysDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditTotalCladdingAreaDrawerShowing && (
          <EditTotalCladdingAreaDrawer
            onSuccess={() => {
              hideEditTotalCladdingAreaDrawer();
            }}
            onClose={() => {
              hideEditTotalCladdingAreaDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditLifeSafetyRiskAssessmentDrawerShowing && (
          <EditLifeSafetyRiskAssessmentDrawer
            onSuccess={() => {
              hideEditLifeSafetyRiskAssessmentDrawer();
            }}
            onClose={() => {
              hideEditLifeSafetyRiskAssessmentDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditAssessmentTypeDrawerShowing && (
          <EditAssessmentTypeDrawer
            onSuccess={() => {
              hideEditAssessmentTypeDrawer();
            }}
            onClose={() => {
              hideEditAssessmentTypeDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditRecommendCladdingDrawerShowing && (
          <EditRecommendCladdingDrawer
            onSuccess={() => {
              hideEditRecommendCladdingDrawer();
            }}
            onClose={() => {
              hideEditRecommendCladdingDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditApplicationFileDrawerShowing && props.questionType && (
          <EditApplicationFileDrawer
            questionType={props.questionType}
            onSuccess={() => {
              hideEditApplicationFileDrawer();
            }}
            onClose={() => {
              hideEditApplicationFileDrawer();
            }}
          />
        )}

        {isEditBsrRegistrationCodeDrawerShowing && (
          <EditBsrRegistrationCodeDrawer
            onSuccess={() => {
              hideEditBsrRegistrationCodeDrawer();
            }}
            onClose={() => {
              hideEditBsrRegistrationCodeDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditBuildingNameDrawerShowing && (
          <EditBuildingNameDrawer
            onSuccess={() => {
              hideEditBuildingNameDrawer();
            }}
            onClose={() => {
              hideEditBuildingNameDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditFraewSurveyCompanyDrawerShowing && (
          <EditFraewSurveyCompanyDrawer
            onSuccess={() => {
              hideEditFraewSurveyCompanyDrawer();
            }}
            onClose={() => {
              hideEditFraewSurveyCompanyDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditFraewSurveyDateDrawerShowing && (
          <EditFraewSurveyDateDrawer
            onSuccess={() => {
              hideEditFraewSurveyDateDrawer();
            }}
            onClose={() => {
              hideEditFraewSurveyDateDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditFraewInstructionDateDrawerShowing && (
          <EditFraewInstructionDateDrawer
            onSuccess={() => {
              hideEditFraewInstructionDateDrawer();
            }}
            onClose={() => {
              hideEditFraewInstructionDateDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditFraewReportAuthorDrawerShowing && (
          <EditFraewReportAuthorDrawer
            onSuccess={() => {
              hideEditFraewReportAuthorDrawer();
            }}
            onClose={() => {
              hideEditFraewReportAuthorDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditFraewPeerReviewerDrawerShowing && (
          <EditFraewPeerReviewerDrawer
            onSuccess={() => {
              hideEditFraewPeerReviewerDrawer();
            }}
            onClose={() => {
              hideEditFraewPeerReviewerDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditFraewCostDrawerShowing && (
          <EditFraewCostDrawer
            onSuccess={() => {
              hideEditFraewCostDrawer();
            }}
            onClose={() => {
              hideEditFraewCostDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditProjectMilestonesDrawerShowing && (
          <EditProjectMilestonesDrawer
            onSuccess={() => {
              hideEditProjectMilestonesDrawer();
            }}
            onClose={() => {
              hideEditProjectMilestonesDrawer();
            }}
          />
        )}
      </>
    );
  }
);
