import { v4 as uuidv4 } from 'uuid';
import { DataTableResponse } from 'common/types/DataTableResponse';
import { EApplicationInternalStatus } from 'enums/EApplicationInternalStatus';
import { EApplicationStage } from 'enums/EApplicationStage';
import { EApplicationStatus } from 'enums/EApplicationStatus';
import {
  EHoldStatus,
  EOnHoldType,
  GetApplicationHoldLatestResponse,
} from 'types/applications/ApplicationHoldTypes';
import {
  IApplicationContactDetails,
  IApplicationGridListItem,
  IApplicationKpis,
  IApplicationSummary,
} from 'types/applications/ApplicationTypes';
import {
  EThirdPartyReferrerType,
  IThirdPartyReferrer,
} from 'types/applications/ReferralTypes';

const data = [
  {
    id: uuidv4(),
    referenceNumber: 'APP1234567',
    buildingName: '520 Liberty Road',
    buildingAddress: 'London, SE1 1WP',
    applicantName: 'Marcus Illustration',
    companyName: 'Diamond Construction',
    creationDate: '2023-03-02 10:00:00',
    assigneeName: 'Rebecca Atkins',
    stage: EApplicationStage.ApplyForGrant,
    status: EApplicationStatus.ApplicationInProgress,
    internalStatus: EApplicationInternalStatus.ApplicationStarted,
  },
  {
    id: uuidv4(),
    referenceNumber: 'APP1234568',
    buildingName: '520 Liberty Road',
    buildingAddress: 'London, SE1 1WP',
    applicantName: 'Marcus Illustration',
    companyName: 'Diamond Construction',
    creationDate: '2023-03-02 10:00:00',
    assigneeName: 'Rebecca Atkins',
    stage: EApplicationStage.ApplyForGrant,
    status: EApplicationStatus.GfaIssued,
    internalStatus: EApplicationInternalStatus.ApplicationStarted,
  },
  {
    id: uuidv4(),
    referenceNumber: 'APP1234569',
    buildingName: '520 Liberty Road',
    buildingAddress: 'London, SE1 1WP',
    applicantName: 'Marcus Illustration',
    companyName: 'Diamond Construction',
    creationDate: '2023-03-02 10:00:00',
    assigneeName: 'Rebecca Atkins',
    stage: EApplicationStage.ApplyForGrant,
    status: EApplicationStatus.Completed,
    internalStatus: EApplicationInternalStatus.ApplicationStarted,
  },
  {
    id: uuidv4(),
    referenceNumber: 'APP1234570',
    buildingName: '520 Liberty Road',
    buildingAddress: 'London, SE1 1WP',
    applicantName: 'Marcus Illustration',
    companyName: 'Diamond Construction',
    creationDate: '2023-03-02 10:00:00',
    assigneeName: 'Rebecca Atkins',
    stage: EApplicationStage.WorksCompleted,
    status: EApplicationStatus.ApplicationApproved,
    internalStatus: EApplicationInternalStatus.ApplicationStarted,
  },
  {
    id: uuidv4(),
    referenceNumber: 'APP1234571',
    buildingName: '520 Liberty Road',
    buildingAddress: 'London, SE1 1WP',
    applicantName: 'Marcus Illustration',
    companyName: 'Diamond Construction',
    creationDate: '2023-03-02 10:00:00',
    assigneeName: 'Rebecca Atkins',
    stage: EApplicationStage.GrantFundingAgreement,
    status: EApplicationStatus.GfaComplete,
    internalStatus: EApplicationInternalStatus.ApplicationStarted,
  },
  {
    id: uuidv4(),
    referenceNumber: 'APP1234572',
    buildingName: '520 Liberty Road',
    buildingAddress: 'London, SE1 1WP',
    applicantName: 'Marcus Illustration',
    companyName: 'Diamond Construction',
    creationDate: '2023-03-02 10:00:00',
    assigneeName: 'Rebecca Atkins',
    stage: EApplicationStage.WorksCompleted,
    status: EApplicationStatus.Completed,
    internalStatus: EApplicationInternalStatus.ApplicationStarted,
  },
  {
    id: uuidv4(),
    referenceNumber: 'APP1234573',
    buildingName: '520 Liberty Road',
    buildingAddress: 'London, SE1 1WP',
    applicantName: 'Marcus Illustration 2',
    companyName: 'Diamond Construction',
    creationDate: '2023-03-02 10:00:00',
    assigneeName: 'Rebecca Atkins',
    stage: EApplicationStage.WorksPackage,
    status: EApplicationStatus.ApplicationNotEligible,
    internalStatus: EApplicationInternalStatus.ApplicationStarted,
  },
  {
    id: uuidv4(),
    referenceNumber: 'APP1234574',
    buildingName: '520 Liberty Road',
    buildingAddress: 'London, SE1 1WP',
    applicantName: 'Marcus Illustration',
    companyName: 'Diamond Construction',
    creationDate: '2023-03-02 10:00:00',
    assigneeName: 'Rebecca Atkins',
    stage: EApplicationStage.ApplyForGrant,
    status: EApplicationStatus.ApplicationInReview,
    internalStatus: EApplicationInternalStatus.ApplicationStarted,
  },
];

export const applicationsData: DataTableResponse<IApplicationGridListItem> = {
  results: data,
  count: data.length,
};

export const applicationsKpiData: IApplicationKpis = {
  totalApplicationCount: 150,
  applyForGrantCount: 10,
  signGrantFundingAgreementCount: 24,
  addWorksPlanCount: 35,
  worksStartedCount: 9,
  worksCompletedCount: 60,
  notEligibleCount: 72,
};

export const applicationSummaryData: IApplicationSummary = {
  referenceNumber: 'APP1234567',
  buildingName: '520 Liberty Road',
  applicantName: 'Marcus Illustration',
  creationDate: '2023-01-02 10:00:00',
  modifiedDate: '2023-03-02 10:00:00',
  stage: EApplicationStage.WorksPackage,
  status: EApplicationStatus.ApplicationInProgress,
  internalStatus: EApplicationInternalStatus.ApplicationStarted,
  isGrantCertifyingOfficerCaptured: true,
  isVendorPaymentApprovalWaiting: true,
  isScheduleOfWorksSubmitted: false,
  closedReason: 'Application closed as was a duplicate',
  isVariationInProgress: true,
  variationStartDate: '2023-01-02 10:00:00',
};

export const applicationContactDetailsData: IApplicationContactDetails = {
  phoneNumber: '012345 123456',
  emailAddress: 'applicant@company1.com',
}

export const thirdPartyReferrers: IThirdPartyReferrer[] = [
  {
    id: 1,
    name: 'Company 1',
    email: 'email@company1.com',
    phone: '071717171718',
    type: EThirdPartyReferrerType.CostConsultant,
  },
  {
    id: 2,
    name: 'Company 2',
    email: 'email@company2.com',
    phone: '078979797777',
    type: EThirdPartyReferrerType.Legal,
  },
];

export const holdLatestData: GetApplicationHoldLatestResponse = {
  holdStatus: EHoldStatus.OnHold,
  date: '2023-10-10T10:05:00',
  reason: 'Just cos innit bruv',
  user: 'Clancy Moped',
  holdType: EOnHoldType.Variation,
};

export const closeApplicationsReasonsData = [
  {
    id: 1,
    name: 'Other',
    isOther: true,
  },
  {
    id: 2,
    name: 'Application in CMS',
    isOther: false,
  },
  {
    id: 3,
    name: 'Alternative Funding - BSF',
    isOther: false,
  },
  {
    id: 4,
    name: 'Alternative Funding - Private Sector',
    isOther: false,
  },
  {
    id: 5,
    name: 'Alternative Funding - RAS',
    isOther: false,
  },
  {
    id: 6,
    name: 'Alternative Funding - Social Sector',
    isOther: false,
  },
  {
    id: 7,
    name: 'Duplicate',
    isOther: false,
  },
  {
    id: 8,
    name: 'Height Over 18m',
    isOther: false,
  },
  {
    id: 9,
    name: 'Height Under 11m',
    isOther: false,
  },
  {
    id: 10,
    name: 'No Qualifying Lease - Private Sector',
    isOther: false,
  },
  {
    id: 11,
    name: 'No Qualifying Lease - Social Sector',
    isOther: false,
  },
  {
    id: 12,
    name: 'No Work Required - Following RE Response',
    isOther: false,
  },
  {
    id: 13,
    name: 'No Work Required - Following FRAEW',
    isOther: false,
  },
  {
    id: 14,
    name: 'Withdrawn',
    isOther: false,
  },
  {
    id: 15,
    name: 'Works Commenced',
    isOther: false,
  },
];

export const applicationReasonsDataJourneyTimeline = [
  {
    status: 'In Progress',
    stage: 'Apply for Grant',
    timestamp: '2023-01-02 10:00:00',
    durationWorkingDays: 3,
    totalDurationWorkingDays: 4
  },
  {
    status: 'Submitted',
    stage: 'Apply for Grant',
    timestamp: '2023-01-02 15:00:00',
    durationWorkingDays: 13,
    totalDurationWorkingDays: 14
  }
];

export const interventionCategoriesData = [
  {
    id: 1,
    name: 'Behavioural',
  },
  {
    id: 2,
    name: 'Complexity',
  },
  {
    id: 3,
    name: 'Policy/issues',
  },
  {
    id: 4,
    name: 'Capability (low)',
  },
  {
    id: 5,
    name: 'Sequence of programmes',
  },
];

export const interventionCategoryData =
{
  interventionCategoryId: 1,
  interventionCategoryName: 'Behavioural',
};

export const ragRatingsData = [
  {
    id: 1,
    name: 'Low',
  },
  {
    id: 2,
    name: 'Medium',
  },
  {
    id: 3,
    name: 'High',
  },
];

export const ragRatingData =
{
  ratingId: 2,
  ratingName: 'Medium',
};